import {
  COMPANY_DESCRIPTION,
  COMPANY_NAME,
  COMPANY_SLOGAN,
  DOMAIN,
  REDDIT_DESCRIPTION,
  REDDIT_SLOGAN,
  REDDIT_URL,
} from '@/shared/constants/company'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import { type Route } from 'next'

export const ROUTE = {
  BLOG: '/blog',
  DASHBOARD: '/dashboard',
  GDPR_COMMITMENT: '/gdpr-commitment',
  GMAIL: '/gmail',
  HOME: '/',
  INVITE: '/invite/[token]' as Route,
  REDDIT: '/',
  REDDIT_OLD: '/reddit',
  REDDIT_DASHBOARD: '/apps/reddit',
  REDDIT_COMMENTS: '/apps/reddit/comments',
  REDDIT_KEYWORDS: '/apps/reddit/keywords',
  REDDIT_REPLIES: '/apps/reddit/replies',
  REDDIT_POSTS: '/apps/reddit/posts',
  REDDIT_SETTINGS: '/apps/reddit/settings',
  PRICING: '/pricing',
  PRIVACY_SHIELD: '/privacy-shield',
  PRIVACY: '/privacy',
  SETTINGS_ADMIN: '/settings/admin',
  SETTINGS_CHANGE_PASSWORD: '/settings/change-password',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_SUBSCRIPTION_CHANGE_PLAN: '/settings/subscription/change-plan',
  SETTINGS_SUBSCRIPTION: '/settings/subscription',
  SIGN_IN: '/sign-in',
  SIGN_UP_EMAIL: '/email-sign-up',
  SIGN_IN_EMAIL: '/email-sign-in',
  SLACK: '/slack',
  SUPPORT: '/support',
  TASK_MANAGEMENT: '/task-management',
  TASKS: '/tasks',
  TERMS: '/terms',
  THEME: '/theme',
  YOUTUBE: '/youtube',
  YOUTUBE_DASHBOARD: '/apps/youtube',
  // TODO
} satisfies Record<string, Route>
export const NO_NAVBAR_PATHS: Route[] = [
  ROUTE.THEME,
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP_EMAIL,
  ROUTE.SIGN_IN_EMAIL,
  ROUTE.INVITE,
] as const
export const PUBLIC_PATHS: Route[] = [
  ROUTE.BLOG,
  ROUTE.GDPR_COMMITMENT,
  ROUTE.GMAIL,
  ROUTE.HOME,
  ROUTE.INVITE,
  ROUTE.PRICING,
  ROUTE.PRIVACY_SHIELD,
  ROUTE.PRIVACY,
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP_EMAIL,
  ROUTE.SIGN_IN_EMAIL,
  ROUTE.SLACK,
  ROUTE.SUPPORT,
  ROUTE.TASK_MANAGEMENT,
  ROUTE.TERMS,
  ROUTE.REDDIT,
  ROUTE.REDDIT_OLD,
  ROUTE.YOUTUBE,
] as const
export const NO_FOOTER_PATHS: Route[] = [
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP_EMAIL,
  ROUTE.SIGN_IN_EMAIL,
  ROUTE.INVITE,
] as const

export const ROOT_UNAUTHENTICATED_ROUTES = [
  ROUTE.REDDIT,
  ROUTE.TASK_MANAGEMENT,
  ROUTE.GMAIL,
  ROUTE.YOUTUBE,
  ROUTE.SLACK,
  ROUTE.HOME,
] satisfies Route[]
export const ROOT_AUTHENTICATED_ROUTES = [
  ROUTE.REDDIT_DASHBOARD,
  ROUTE.DASHBOARD,
] satisfies Route[]
export const ROOT_ROUTE_AUTHENTICATED_UNAUTHENTICATED_MAP = {
  [ROUTE.REDDIT_DASHBOARD]: ROUTE.REDDIT,
  [ROUTE.YOUTUBE_DASHBOARD]: ROUTE.YOUTUBE,
} satisfies Record<Route, Route>
export const ROOT_ROUTE_UNAUTHENTICATED_AUTHENTICATED_MAP = {
  ..._.invert(ROOT_ROUTE_AUTHENTICATED_UNAUTHENTICATED_MAP),
  [ROUTE.REDDIT_OLD]: ROUTE.REDDIT_DASHBOARD,
} as Partial<Record<Route, Route>>
export const ROOT_UNAUTHENTICATED_ROUTE_INTEGRATION_MAP = {
  [ROUTE.REDDIT]: IntegrationName.REDDIT,
  [ROUTE.REDDIT_OLD]: IntegrationName.REDDIT,
  [ROUTE.GMAIL]: IntegrationName.GMAIL,
  [ROUTE.YOUTUBE]: IntegrationName.YOUTUBE,
} satisfies Record<Route, Route>
export const INTEGRATION_NAME_ROOT_AUTHENTICATED_ROUTE_MAP: Partial<
  Record<IntegrationName, Route>
> = {
  [IntegrationName.REDDIT]: ROUTE.REDDIT_DASHBOARD,
}
export const ROOT_ROUTES = [
  ...ROOT_UNAUTHENTICATED_ROUTES,
  ...ROOT_AUTHENTICATED_ROUTES,
] satisfies Route[]
export const DEFAULT_ROOT_AUTHENTICATED_ROUTE = ROUTE.REDDIT_DASHBOARD
export const DEFAULT_ROOT_UNAUTHENTICATED_ROUTE = ROUTE.HOME
export const ROUTE_PAGE_HEAD_DATA: Partial<Record<Route, PageHeadDataType>> = {
  [ROUTE.REDDIT]: {
    title: REDDIT_SLOGAN,
    description: REDDIT_DESCRIPTION,
    url: REDDIT_URL,
  },
  [ROUTE.REDDIT_DASHBOARD]: {
    title: REDDIT_SLOGAN,
    description: REDDIT_DESCRIPTION,
    url: REDDIT_URL,
  },
}

export const DEFAULT_PAGE_HEAD_DATA: PageHeadDataType = {
  title: `${COMPANY_NAME} - ${COMPANY_SLOGAN}`,
  description: COMPANY_DESCRIPTION,
  url: DOMAIN,
}
export type PageHeadDataType = {
  title: string
  description: string
  url: string
}
