'use client'

import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

export const usePageTitle = () => {
  const router = useRouter()

  const [pageTitle, setPageTitle] = React.useState<string | null>(null)

  useEffect(() => {
    if (router.route.length <= 1 || !router.isReady) {
      if (pageTitle != null) {
        setPageTitle(null)
      }
      return
    }

    // Use the capitalized end of the path as the page title
    const path = router.pathname
    const pathParts = path.split('/')
    let title = _.startCase(pathParts.pop()?.replace(/-/g, ' '))

    if (title === 'Index') {
      title = 'Dashboard'
    }

    if (path.includes('apps/')) {
      // Prefix with the app name, which is the part after /apps/
      const appNameIndex = pathParts.indexOf('apps') + 1
      const appName = pathParts[appNameIndex]
      if (appNameIndex > 0 && appName) {
        title = `${appName.replace(/^\w/, c => c.toUpperCase())} ${title}`
      }
    }

    setPageTitle(title)
  }, [router.isReady, router.pathname, router.route])

  return pageTitle
}
