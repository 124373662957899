import { type AllContextDataType } from '@/shared/constants/context'
import {
  type GmailContextDataType,
  type GmailEmailType,
} from '@/shared/constants/gmail'
import { type GoogleContactType } from '@/shared/constants/google'
import {
  type TaskWithContextItems,
  type TaskWithIntegrationContextItems,
} from '@/shared/constants/task'
import { hasContext } from '@/shared/utils/context'
import { parseEmailString, sanitizeEmailBody } from '@/shared/utils/email'
import { stripHtml } from '@/shared/utils/html'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'

/////////////
// GETTERS //
/////////////

export function getContact({
  emailString,
  contacts,
}: {
  emailString: string
  contacts?: GoogleContactType[] | null
}): GoogleContactType | null {
  const { email, name } = parseEmailString(emailString)
  if (!email) {
    return null
  }

  const contact = contacts?.find(
    contact => contact.email?.toLowerCase() === email.toLowerCase()
  )
  return contact ?? { name: name ?? undefined, email }
}

export function getEmailBodyString(
  email: GmailEmailType,
  {
    isRaw,
    isPreferPlainText,
  }: { isRaw?: boolean; isPreferPlainText?: boolean } = {}
): string | null {
  if (isRaw) {
    if ((isPreferPlainText || !email.body?.html) && email.body?.plain) {
      return sanitizeEmailBody(stripHtml(email.body.plain), {
        isRemovingSignature: false,
      })
    }

    if (email.body?.html) {
      return sanitizeEmailBody(stripHtml(email.body.html), {
        isRemovingSignature: false,
      })
    }

    console.trace('No body found in email: %O', email)
    return null
  }

  if (email.summary) {
    return email.summary.trim()
  }

  console.trace('No summary found in email: %O', email)
  return null
}

export function getTaskOriginEmail(
  contextData: AllContextDataType
): GmailEmailType | null {
  if (!isGmailData(contextData)) {
    return null
  }

  const emails = contextData.emails
  const taskOriginEmail =
    emails.find(email => email.isTaskOrigin) ??
    // TODO: Assert task is Gmail to make this assumption
    emails.length === 1
      ? emails[0]!
      : null

  return taskOriginEmail ?? null
}

export function getUrl(email: GmailEmailType): string {
  return `https://mail.google.com/mail/u/0/#all/${email.threadId}`
}

//////////////
// CHECKERS //
//////////////

export function isGmailTask(
  task: TaskWithContextItems
): task is TaskWithIntegrationContextItems<typeof IntegrationName.GMAIL> & {
  integrationName: typeof IntegrationName.GMAIL
} {
  return task.integrationName === IntegrationName.GMAIL && hasGmailData(task)
}

export function hasGmailData(
  task: TaskWithContextItems
): task is TaskWithIntegrationContextItems<typeof IntegrationName.GMAIL> {
  return hasContext(task, IntegrationName.GMAIL)
}

export const isGmailData = (data: unknown): data is GmailContextDataType => {
  return (
    !!data &&
    typeof data === 'object' &&
    'emails' in data &&
    Array.isArray(data.emails)
  )
}

export function isInboxEmail(email: GmailEmailType): boolean {
  return email.labels.includes('INBOX')
}

export function isSpamEmail(email: GmailEmailType): boolean {
  return email.labels.includes('SPAM')
}

export function isArchivedEmail(email: GmailEmailType): boolean {
  return !email.labels.includes('INBOX') && !isSpamEmail(email)
}

export function isUnreadEmail(email: GmailEmailType): boolean {
  return email.labels.includes('UNREAD')
}

export function isDraftEmail(email: GmailEmailType): boolean {
  return email.labels.includes('DRAFT')
}

//////////////
// UPDATERS //
//////////////

export function archiveEmail(email: GmailEmailType): GmailEmailType {
  return {
    ...email,
    labels: _.without(email.labels, 'INBOX'),
  }
}

export function reportSpamEmail(email: GmailEmailType): GmailEmailType {
  return archiveEmail({
    ...email,
    labels: _.uniq([...email.labels, 'SPAM']),
  })
}

export function moveEmailToInbox(email: GmailEmailType): GmailEmailType {
  return markNotSpamEmail({
    ...email,
    labels: _.uniq([...email.labels, 'INBOX']),
  })
}

export function markNotSpamEmail(email: GmailEmailType): GmailEmailType {
  return {
    ...email,
    labels: _.without(email.labels, 'SPAM'),
  }
}
