import { PUBLIC_PATHS } from '@/web/constants/router'
import { hasRootPathMatch } from '@/web/utils/router'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useHasSidebar = () => {
  const router = useRouter()
  const session = useSession()

  const [hasSidebar, setHasSidebar] = useState(false)

  useEffect(() => {
    if (
      !router.isReady ||
      hasRootPathMatch(router.pathname, PUBLIC_PATHS) ||
      session.status !== 'authenticated'
    ) {
      setHasSidebar(false)
    } else {
      setHasSidebar(true)
    }
  }, [session.status, router.isReady, router.pathname])

  return hasSidebar
}
