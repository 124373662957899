import type {
  FeatureType,
  PricingTierLimitType,
} from '@/shared/constants/pricing'
import {
  getActivityOrFeatureLimitByTierId,
  getTierId,
} from '@/shared/utils/subscription'
import { api } from '@/web/utils/api'
import { type ActivityType } from '@prisma/client'
import { useSession } from 'next-auth/react'

export const useActivityOrFeatureLimit = (
  activityOrFeatureType: ActivityType | FeatureType
): { limit: PricingTierLimitType; isLoading: boolean } => {
  const session = useSession()

  const subscriptionInfoQueryResult = api.subscription.info.useQuery(
    undefined,
    {
      enabled: session.status === 'authenticated',
    }
  )
  const currentTierId = getTierId({
    subscription: subscriptionInfoQueryResult.data,
    role: session.data?.user?.role,
  })
  const limit = getActivityOrFeatureLimitByTierId(
    currentTierId,
    activityOrFeatureType
  )

  return { isLoading: subscriptionInfoQueryResult.isLoading, limit }
}
