import {
  PRIORITIZING_WORDS_PER_MINUTE,
  READING_WORDS_PER_MINUTE,
  WRITING_WORDS_PER_MINUTE,
} from '@/shared/constants/time'
import dayjs from '@/shared/singletons/dayjs'

export function getTimeForWords(
  readWordCount: number,
  writeWordCount: number
): number {
  return (
    readWordCount * READING_WORDS_PER_MINUTE +
    writeWordCount * WRITING_WORDS_PER_MINUTE
  )
}
export function getMillis({
  readWordCount,
  writeWordCount,
}: {
  readWordCount?: number
  writeWordCount?: number
}): number {
  const timeToRead = getMillisToRead(readWordCount)
  const timeToPrioritize = getMillisToPrioritize(readWordCount)
  const timeToWrite = getMillisToWrite(writeWordCount)

  // console.log(
  //   'Calculating total time for %s words read and %s words written',
  //   readWordCount,
  //   writeWordCount,
  //   {
  //     timeToRead,
  //     timeToPrioritize,
  //     timeToWrite,
  //   }
  // )
  return timeToRead + timeToPrioritize + timeToWrite
}
export function getTimeString({
  readWordCount,
  writeWordCount,
}: {
  readWordCount?: number
  writeWordCount?: number
}): string {
  const millis = getMillis({ readWordCount, writeWordCount })
  if (!millis) {
    return '0 seconds'
  }

  return dayjs.duration(millis, 'milliseconds').humanize()
}
export function getTimeToRead(wordCount: number): string {
  return dayjs.duration(wordCount / 200, 'minutes').humanize() // a minute
}
export function getMillisToRead(wordCount?: number): number {
  return ((wordCount ?? 0) / READING_WORDS_PER_MINUTE) * 60 * 1000
}
export function getMillisToWrite(wordCount?: number): number {
  return ((wordCount ?? 0) / WRITING_WORDS_PER_MINUTE) * 60 * 1000
}
export function getMillisToPrioritize(wordCount?: number): number {
  return ((wordCount ?? 0) / PRIORITIZING_WORDS_PER_MINUTE) * 60 * 1000
}

export function getDateFromTime(time: string, timeZone: string): Date {
  return dayjs.tz(time, 'HH:mm A', timeZone).toDate()
}

export function hasPassedTime(
  date: Date,
  time: string, // Must be in the format 'HH:mm A'
  timeZone: string
): boolean {
  const now = dayjs(date).tz(timeZone)
  const compareDate = getDateFromTime(time, timeZone)
  const result = now.isAfter(compareDate)
  console.log(
    'Is now (%s) after compareDate (%s): %o',
    now.toDate().toISOString(),
    compareDate.toISOString(),
    result
  )
  return result
}
