import { Badge } from '@/web/components/ui/badge'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import { type NavigationItemType } from '@/web/constants/navigation'
import { cn } from '@/web/libs/utils'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import router from 'next/router'
import { useMemo, type ComponentProps } from 'react'

function findBestMatchNavigationItem(
  items: NavigationItemType[],
  pathname: string
): NavigationItemType | null {
  const exactMatches = items.filter(
    item => item.href.toLowerCase() === pathname
  )
  if (exactMatches.length > 0) {
    const exactMatch =
      exactMatches.find(item => !item.children) ?? exactMatches[0] ?? null
    console.log(
      'Path %o is exact match for navigation item %o',
      pathname,
      exactMatch
    )
    return exactMatch
  }

  const partialMatches = items.filter(
    item =>
      item.href.toLowerCase().startsWith(pathname) ||
      pathname.startsWith(item.href.toLowerCase()) ||
      (item.basePath && pathname.startsWith(item.basePath))
  )
  if (partialMatches.length > 0) {
    let bestMatch = null
    for (const partialMatch of partialMatches) {
      if (
        bestMatch === null ||
        partialMatch.href.length > bestMatch.href.length
      ) {
        bestMatch = partialMatch
      }
    }

    console.log(
      'Path %o is partial match for navigation item %o',
      pathname,
      bestMatch
    )
    return bestMatch
  }

  console.log('No matching navigation item found for path %o', pathname, items)
  return null
}

const NavButton = ({
  item,
  selectedNavigationItem,
  onClick,
  isExpanded,
  isShowingChildren,
  isChild,
}: Pick<ComponentProps<typeof NavButtonList>, 'onClick'> & {
  item: NavigationItemType
  selectedNavigationItem: NavigationItemType | null
  isExpanded: boolean
  isShowingChildren: boolean
  isChild?: boolean
}) => {
  const badgeHookResult = item.useBadgeHook ? item.useBadgeHook() : null
  const warningHookResult = item.useWarningHook ? item.useWarningHook() : null
  const visibleHookResult = item.useVisibleHook ? item.useVisibleHook() : null
  const isSelected = selectedNavigationItem === item

  return (
    <li
      key={item.name}
      className={cn(
        'shrink-0',
        visibleHookResult === null || visibleHookResult === true
          ? undefined
          : 'hidden',
        isSelected &&
          isShowingChildren &&
          item.children &&
          !isExpanded &&
          'flex-col flex items-center border border-border rounded-full'
      )}>
      <Link href={item.href} className='w-full'>
        <Button
          variant='sidebar'
          title={item.name}
          state={isSelected ? 'selected' : undefined}
          className={cn(
            'gap-4',
            !isExpanded && 'size-10 justify-center items-center p-0',
            isChild && 'size-8'
          )}
          onClick={onClick}
          startIcon={
            item.icon ? (
              <item.icon
                className={cn('size-4 shrink-0', isChild && 'size-3')}
                aria-hidden='true'
              />
            ) : null
          }>
          {isExpanded ? (
            <div className='flex w-full flex-row items-center justify-between'>
              {item.name}
              {badgeHookResult != null ? (
                <Badge size='xs' theme='gradient'>
                  {badgeHookResult}
                </Badge>
              ) : null}
              {warningHookResult ? (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='lg'
                  className='text-destructive'
                  title={
                    typeof warningHookResult === 'string'
                      ? warningHookResult
                      : undefined
                  }
                />
              ) : undefined}
            </div>
          ) : null}
        </Button>
      </Link>
      {
        //isExpanded &&
        isShowingChildren && item.children && isSelected ? (
          isExpanded ? (
            <div className='pl-5'>
              <NavButtonList
                items={item.children}
                className='pl-5 pt-1 border-l border-border'
                onClick={onClick}
                isOpen={isExpanded}
              />
            </div>
          ) : (
            <div className='py-1'>
              <NavButtonList
                items={item.children}
                onClick={onClick}
                isOpen={isExpanded}
                isChild
              />
            </div>
          )
        ) : null
      }
    </li>
  )
}

export const NavButtonList = ({
  items,
  className,
  onClick,
  isOpen = true,
  isShowingChildren = true,
  isChild,
}: {
  className?: string
  items: NavigationItemType[]
  onClick?: ButtonProps['onClick']
  isOpen?: boolean
  isShowingChildren?: boolean
  isChild?: boolean
}) => {
  const selectedNavigationItem = useMemo(
    () => findBestMatchNavigationItem(items, router.pathname),
    [items, router.pathname]
  )
  if (!selectedNavigationItem) {
    console.log('No matching navigation item found', items, router.pathname)
  }

  return (
    <ul
      role='list'
      className={cn(
        'flex w-full shrink-0 flex-col gap-y-1',
        isChild && 'gap-y-0.5',
        className
      )}>
      {items.map(item => {
        return (
          <NavButton
            item={item}
            isExpanded={isOpen}
            key={item.name}
            selectedNavigationItem={selectedNavigationItem}
            onClick={onClick}
            isShowingChildren={isShowingChildren}
            isChild={isChild}
          />
        )
      })}
    </ul>
  )
}
