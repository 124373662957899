import { URL_REGEX_STRING } from '@/shared/constants/regex'
import { z } from 'zod'

export const LooseUrlSchema = z.string().refine(
  url => {
    const result = !!url.match(new RegExp('^' + URL_REGEX_STRING + '$'))?.length
    return result
  },
  {
    message: 'Invalid URL format',
  }
)
