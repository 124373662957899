import { isPending } from '@/shared/utils/action'
import { indent } from '@/shared/utils/string'
import { getBaseUrl } from '@/shared/utils/text'
import type { Action, ActionType, Settings } from '@prisma/client'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  MIN_COMMENT_SCORE_TO_REPLY,
  MIN_POST_SCORE_TO_REPLY,
  MIN_SUBREDDIT_SUBSCRIBERS_TO_REPLY,
  REDDIT_FULLNAME_TYPE,
  REDDIT_FULLNAME_TYPE_PREFIXES,
  type RedditCommentType,
  type RedditFullnamePrefixType,
  type RedditPostType,
  type RedditReplyToCommentParametersType,
  type RedditSettingsInputType,
  type RedditThingType,
  type RedditUserType,
} from '../constants/reddit'

//////////////
// CHECKERS //
//////////////

export function isCommentId(id: string): boolean {
  return id.startsWith('t1_')
}

export function isPostId(id: string): boolean {
  return id.startsWith('t3_')
}

export function isCommentAuthorEqual(
  redditUsername: string,
  comment: RedditCommentType
): boolean {
  return comment.author === redditUsername
}

export function isUserDeleted(username: string): boolean {
  return username === '[deleted]'
}

export function isCommentDeleted(comment: RedditCommentType): boolean {
  return (
    isUserDeleted(comment.author) ||
    comment.body === '[deleted]' ||
    comment.body === '[removed]'
  )
}

export function isPostDeleted(post: RedditPostType): boolean {
  return (
    post.selftext === '[deleted]' ||
    post.selftext === '[removed]' ||
    isUserDeleted(post.author)
  )
}

export function shouldIgnorePost({
  post,
  currentUsername,
  negativeSubreddits,
  negativeUsers,
}: {
  post?: RedditPostType | null
  currentUsername: string
  negativeSubreddits?: string[]
  negativeUsers?: string[]
}): [boolean, string] {
  const reasons: string[] = []

  if (!post) {
    console.log('Post not found — ignoring')
    return [true, 'Post not found']
  }

  if (post.thumbnail === 'nsfw') {
    reasons.push('Post is NSFW')
  }
  if (post.is_video) {
    reasons.push('Post is a video')
  }
  // Ignore crossposts so that we don't reply to the same post multiple times
  // eg. @see https://www.reddit.com/api/info.json?id=t3_1fv2405 vs https://www.reddit.com/api/info.json?id=t3_1fv23lw
  if (!!post.crosspost_parent_list && post.crosspost_parent_list?.length > 0) {
    reasons.push('Post is a crosspost')
  }
  if (post.author_is_blocked) {
    reasons.push('Post author is blocked')
  }
  if (
    post.subreddit &&
    negativeSubreddits?.includes(post.subreddit.toLowerCase())
  ) {
    reasons.push('Post is in negative subreddit')
  }
  if (negativeUsers?.includes(post.author.toLowerCase())) {
    reasons.push('Post author is in negative user list')
  }
  if (post.subreddit_subscribers < MIN_SUBREDDIT_SUBSCRIBERS_TO_REPLY) {
    reasons.push('Subreddit subscribers too low')
  }
  if (post.score < MIN_POST_SCORE_TO_REPLY) {
    reasons.push('Post score too low')
  }
  if (post.author === currentUsername) {
    reasons.push('Post author is current user')
  }
  if (post.author.toLowerCase() === 'automoderator') {
    reasons.push('Post author is automoderator')
  }
  if (isPostDeleted(post)) {
    reasons.push('Post is deleted')
  }
  if (
    new Date(post.created_utc * 1000) < dayjs().subtract(1, 'month').toDate()
  ) {
    reasons.push('Post is too old')
  }

  console.log(
    'Post %o: %s %o',
    post?.id,
    reasons.length ? '❌' : '✅',
    reasons.join(', ')
  )
  return [reasons.length > 0, reasons.join(', ')]
}
export function shouldIgnoreComment({
  comment,
  currentUsername,
  negativeSubreddits,
  negativeUsers,
  commentThread,
}: {
  comment: RedditCommentType | null
  commentThread?: RedditCommentType | null
  currentUsername: string
  negativeSubreddits?: string[]
  negativeUsers?: string[]
}): [boolean, string] {
  const reasons: string[] = []

  if (!comment) {
    console.log('Comment not found — ignoring')
    return [true, 'Comment not found']
  }

  if (
    comment.subreddit &&
    negativeSubreddits?.includes(comment.subreddit.toLowerCase())
  ) {
    reasons.push('Comment is in negative subreddit')
  }
  if (negativeUsers?.includes(comment.author.toLowerCase())) {
    reasons.push('Comment author is in negative user list')
  }
  if (comment.score < MIN_COMMENT_SCORE_TO_REPLY) {
    reasons.push('Comment score too low')
  }
  if (comment.author === currentUsername) {
    reasons.push('Comment author is current user')
  }
  if (isCommentDeleted(comment)) {
    reasons.push('Comment is deleted')
  }
  if (comment.author.toLowerCase() === 'automoderator') {
    reasons.push('Comment author is automoderator')
  }

  const lastCommentByUser =
    commentThread &&
    getLastCommentByUserInThread(commentThread, currentUsername)
  if (
    lastCommentByUser &&
    lastCommentByUser.score < MIN_COMMENT_SCORE_TO_REPLY
  ) {
    reasons.push('Your last comment in the thread’s score is too low')
  }

  console.log(
    'Comment %o: %s %o',
    comment?.id,
    reasons.length ? '❌' : '✅',
    reasons.join(', ')
  )
  return [reasons.length > 0, reasons.join(', ')]
}

/////////////
// STRINGS //
/////////////

function getTypePrefix(
  specifiedType: RedditThingType
): RedditFullnamePrefixType {
  return Object.entries(REDDIT_FULLNAME_TYPE).find(
    ([_prefix, type]) => type === specifiedType
  )?.[0] as RedditFullnamePrefixType
}

export function getFullname({
  type,
  id,
}: {
  type: RedditThingType
  id: string
}): string {
  return `${getTypePrefix(type)}_${id}`
}

function parseFullname(
  fullname: string,
  expectedType?: RedditThingType
): {
  type: RedditThingType
  id: string
} {
  const [typePrefix, id] = fullname.split('_')
  if (!typePrefix) {
    throw new Error(`No fullname type: ${fullname}`)
  }
  if (!id) {
    throw new Error(`No fullname id: ${fullname}`)
  }
  if (
    !REDDIT_FULLNAME_TYPE_PREFIXES.includes(
      typePrefix as RedditFullnamePrefixType
    )
  ) {
    throw new Error(`Invalid fullname type: ${typePrefix}`)
  }

  const type =
    REDDIT_FULLNAME_TYPE[typePrefix.toLowerCase() as RedditFullnamePrefixType]
  if (!type) {
    throw new Error(`No matching fullname type prefix found: ${typePrefix}`)
  }

  if (expectedType && type !== expectedType) {
    throw new Error(
      `Fullname type "${type}" does not match expected type "${expectedType}"`
    )
  }

  return { type, id }
}

export function parseCommentId(fullname: string): string | null {
  return parseFullname(fullname, 'comment').id
}

export function parsePostId(fullname?: string): string | null {
  return fullname ? parseFullname(fullname, 'post').id : null
}

export function getPostString(post: RedditPostType, limit?: number) {
  return `[${post.subreddit}] ${post.author}: "${post.title}"\n${
    post.selftext?.substring(0, limit) ?? '[No Text]'
  }${post.url ? `\n${post.url}` : ''}\n(${getPostUrl(post)}) ${new Date(
    post.created_utc * 1000
  ).toString()}`
}
export function getPostSubstring(post: RedditPostType) {
  return getPostString(post, 50)
}
export function getPostStrings(posts: RedditPostType[]): string[] {
  return posts.map(post => getPostString(post))
}

export function getCommentString(
  comment: RedditCommentType,
  {
    limit,
    isExcludingSubreddit,
  }: { limit?: number; isExcludingSubreddit?: boolean } = {}
) {
  const bodyString = comment.body
    ? `: "${comment.body.substring(0, limit)}${
        limit && limit > comment.body.length ? '...' : ''
      }"`
    : ''
  return `${isExcludingSubreddit ? '' : `[${comment.subreddit}] `}${
    comment.author
  }${bodyString} (Score: ${comment.score})\n(https://reddit.com/r/${
    comment.subreddit
  }/comments/${getCommentPostId(comment)}/comment/${comment.id}) ${new Date(
    comment.created_utc * 1000
  ).toString()}`
}

export function getCommentThreadString(
  comment: RedditCommentType,
  { isExcludingSubreddit }: { isExcludingSubreddit?: boolean } = {}
) {
  if (!comment.replies?.length) {
    return getCommentString(comment, { isExcludingSubreddit })
  }

  return `${getCommentString(comment, { isExcludingSubreddit })}\n${indent(
    getCommentThreadStrings(comment.replies, {
      isExcludingSubreddit: true,
    }).join('\n')
  )}`
}
export function getCommentThreadStrings(
  comments: RedditCommentType[],
  { isExcludingSubreddit }: { isExcludingSubreddit?: boolean } = {}
): string[] {
  return comments.map(comment =>
    getCommentThreadString(comment, { isExcludingSubreddit })
  )
}

export function getCommentSubstring(comment: RedditCommentType) {
  return getCommentString(comment, { limit: 50 })
}

export function getCommentStrings(
  comments: RedditCommentType[],
  limit?: number
) {
  return comments.map(comment => getCommentString(comment, { limit }))
}

export function getCommentThreadSubstring(comment: RedditCommentType): string {
  if (!comment.replies?.length) {
    return getCommentSubstring(comment)
  }

  return `${getCommentSubstring(comment)}\n${indent(
    getCommentThreadSubstrings(comment.replies).join('\n')
  )}`
}

export function getCommentThreadSubstrings(
  comments: RedditCommentType[]
): string[] {
  return comments.map(comment => getCommentThreadSubstring(comment))
}

export function getLastCommentInThread(
  commentThread: RedditCommentType
): RedditCommentType {
  if (!commentThread.replies?.length) {
    return commentThread
  }

  return getLastCommentInThread(commentThread.replies[0]!)
}

////////////
// PUSHER //
////////////

export function getGenerateCommentsChannel(userId: string) {
  return `reddit-ai-generate-comments-${userId}`
}
export function getGenerateRepliesChannel(userId: string) {
  return `reddit-ai-generate-replies-${userId}`
}
export function getGenerateCommentChannel(userId: string, postId: string) {
  return `reddit-ai-generate-comment-${userId}-${postId}`
}
export function getGenerateReplyChannel(userId: string, commentId: string) {
  return `reddit-ai-generate-reply-${userId}-${commentId}`
}
export function getKeywordMatchesChannel(userId: string) {
  return `reddit-ai-keyword-matches-${userId}`
}
export function getKeywordMatchChannel(
  userId: string,
  commentOrPostId: string
) {
  return `reddit-ai-keyword-match-${userId}-${commentOrPostId}`
}

/////////
// URL //
/////////

export function getPostUrl(post: RedditPostType): string {
  return getPostUrlRaw({
    id: post.id,
    subreddit: post.subreddit,
    title: post.title,
  })
}

export function getPostUrlRaw({
  subreddit,
  id,
  title,
}: {
  subreddit: string
  id: string
  title?: string
}): string {
  const baseUrl = getSubredditUrl(subreddit) + '/comments'
  const slug = title
    ? title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '')
    : ''
  return `${baseUrl}/${id}/${slug}`
}

export function getUserUrl(name: string): string {
  const baseUrl = 'https://reddit.com/user/'
  return `${baseUrl}${name}`
}

export function getCommentUrl(comment: RedditCommentType): string {
  return getCommentUrlRaw({
    subreddit: comment.subreddit,
    postId: getCommentPostId(comment),
    commentId: comment.id,
  })
}
export function getCommentUrlRaw({
  subreddit,
  postId,
  commentId,
}: {
  subreddit: string
  postId: string
  commentId: string
}): string {
  const baseUrl = getSubredditUrl(subreddit) + '/comments'
  return `${baseUrl}/${postId}/comment/${commentId}`
}

export function getSubredditUrl(subreddit: string): string {
  const baseUrl = 'https://reddit.com/r/'
  return `${baseUrl}${subreddit}`
}

// CHECKERS //

export function isCommentAuthorOP(
  comment: RedditCommentType,
  post: RedditPostType
): boolean {
  return comment.author === post.author
}

export function hasIncompleteOrMissingCommentReplyAction({
  replyToCommentActions,
  commentThreadRoot,
  redditUsername,
}: {
  replyToCommentActions: (Action & {
    type: typeof ActionType.REPLY_TO_COMMENT
    parameters: RedditReplyToCommentParametersType
  })[]
  commentThreadRoot: RedditCommentType
  redditUsername: string
}): boolean {
  const commentsReplyingToUser = getRepliesToUser({
    commentThreadRoot,
    redditUsername,
  })

  return commentsReplyingToUser.some(comment => {
    return !replyToCommentActions.some(
      replyToCommentAction =>
        replyToCommentAction.parameters?.commentId === comment.id &&
        !isPending(replyToCommentAction)
    )
  })
}

////////////////////
// COMMENT THREAD //
////////////////////

export function countCommentThreadRepliesToUser(
  redditUsername: string,
  commentThread: RedditCommentType
): number {
  return (
    commentThread.replies?.reduce(
      (count, reply) =>
        count +
        (isCommentAuthorEqual(redditUsername, reply) ? 0 : 1) +
        countCommentThreadRepliesToUser(redditUsername, reply),
      0
    ) ?? 0
  )
}

export function countAllCommentThreadRepliesToUser(
  redditUsername: string,
  commentThreads: RedditCommentType[]
): number {
  return commentThreads.reduce(
    (count, thread) =>
      count + countCommentThreadRepliesToUser(redditUsername, thread),
    0
  )
}

export function filterCommentThreadToOnlyIncludePathToComment(
  commentThread: RedditCommentType,
  commentId: string
): RedditCommentType | null {
  if (commentThread.id === commentId) {
    return {
      ...commentThread,
      replies: undefined,
    }
  }

  if (!commentThread.replies?.length) {
    return null
  }

  for (const reply of commentThread.replies) {
    const replyThreadToComment = filterCommentThreadToOnlyIncludePathToComment(
      reply,
      commentId
    )

    if (replyThreadToComment) {
      return {
        ...commentThread,
        replies: [replyThreadToComment],
      }
    }
  }

  return null
}

export function getCommentInThread(
  commentThread: RedditCommentType,
  commentId: string
): RedditCommentType | null {
  if (commentThread.id === commentId) {
    return {
      ...commentThread,
      replies: undefined,
    }
  }

  if (!commentThread.replies?.length) {
    return null
  }

  for (const reply of commentThread.replies) {
    const commentInThread = getCommentInThread(reply, commentId)
    if (commentInThread) {
      return {
        ...commentInThread,
        replies: undefined,
      }
    }
  }

  return null
}

function getLastCommentByUserInThread(
  commentThread: RedditCommentType,
  redditUsername: string
): RedditCommentType | null {
  for (const reply of commentThread.replies ?? []) {
    if (reply.author === redditUsername) {
      return getLastCommentByUserInThread(reply, redditUsername) || reply
    }
  }

  return null
}

// COMMENTS //

export function getCommentsNotByUser({
  commentThread,
  redditUsername,
}: {
  commentThread: RedditCommentType
  redditUsername: string
}): RedditCommentType[] {
  if (commentThread.author === redditUsername) {
    return []
  }

  return [commentThread].concat(
    commentThread.replies?.flatMap(reply =>
      getCommentsNotByUser({ commentThread: reply, redditUsername })
    ) ?? []
  )
}

// REPLIES //

export function getRepliesToUser({
  commentThreadRoot,
  redditUsername,
}: {
  commentThreadRoot: RedditCommentType
  redditUsername: string
}): RedditCommentType[] {
  if (commentThreadRoot.author !== redditUsername) {
    return (
      commentThreadRoot.replies?.flatMap(reply =>
        getRepliesToUser({ commentThreadRoot: reply, redditUsername })
      ) ?? []
    )
  }

  return (
    commentThreadRoot.replies?.flatMap(reply =>
      getCommentsNotByUser({
        commentThread: reply,
        redditUsername,
      })
    ) ?? []
  )
}

export function getUnrespondedReplies(
  redditUsername: string,
  comment: RedditCommentType,
  isReplyToUser = false
): RedditCommentType[] {
  const isCommentByUser = isUserCommentAuthor(redditUsername, comment)
  const unrespondedReplies: RedditCommentType[] = []

  if (!isCommentByUser && isReplyToUser) {
    const userReplies = getUserRepliesToComment(redditUsername, comment)
    if (userReplies.length === 0) {
      console.log(
        'Reply to %o is unresponded:\n%s',
        redditUsername,
        getCommentThreadString(comment)
      )
      unrespondedReplies.push(comment)
    } else {
      for (const userReply of userReplies) {
        unrespondedReplies.push(
          ...getUnrespondedReplies(redditUsername, userReply, true)
        )
      }
    }
  }

  for (const reply of comment.replies ?? []) {
    console.log('Checking for unresponded replies to reply %o', reply.id)
    unrespondedReplies.push(
      ...getUnrespondedReplies(redditUsername, reply, isCommentByUser)
    )
  }

  return _.uniqBy(unrespondedReplies, 'id')
}

export function getUserReplyToComment(
  redditUsername: string,
  comment: RedditCommentType
): RedditCommentType | null {
  for (const reply of comment.replies ?? []) {
    if (isUserCommentAuthor(redditUsername, reply)) {
      return reply
    }

    const userReply = getUserReplyToComment(redditUsername, reply)
    if (userReply) {
      return userReply
    }
  }

  return null
}

export function getUserRepliesToComment(
  redditUsername: string,
  comment: RedditCommentType
): RedditCommentType[] {
  // Only collect direct replies authored by the user
  return (comment.replies ?? []).filter(reply =>
    isUserCommentAuthor(redditUsername, reply)
  )
}

export function hasUserRespondedToComment(
  redditUsername: string,
  comment: RedditCommentType
): boolean {
  return !!comment.replies?.some(
    reply =>
      isUserCommentAuthor(redditUsername, reply) ||
      hasUserRespondedToComment(redditUsername, reply)
  )
}

export function isUserCommentAuthor(
  redditUsername: string,
  comment: RedditCommentType
): boolean {
  return comment.author === redditUsername
}
export function getCommentPostId(comment: RedditCommentType): string {
  try {
    const { id } = comment.link_id
      ? parseFullname(comment.link_id, 'post')
      : parseFullname(comment.parent_id, 'post')
    return id
  } catch (error) {
    console.log('No post id for comment %o %O', comment.link_id, comment)
    console.error(error)
    return ''
  }
}

export function getCommentAuthor(
  comment: RedditCommentType,
  users: RedditUserType[]
): RedditUserType | null {
  const author = users.find(user => user.name === comment.author)
  return author ?? null
}

//////////////
// SETTINGS //
//////////////

export function getEnabledSubreddits(
  redditSettings: RedditSettingsInputType
): string[] {
  const subreddits = redditSettings?.subreddits?.map(s => s.toLowerCase()) ?? []
  const negativeSubreddits =
    redditSettings?.negativeSubreddits?.map(s => s.toLowerCase()) ?? []

  return subreddits.filter(subreddit => !negativeSubreddits.includes(subreddit))
}

export function getUpdatedSubredditSettings({
  existingSubreddits,
  updatedSubreddits,
  existingNegativeSubreddits,
  updatedNegativeSubreddits,
}: {
  existingSubreddits?: string[]
  updatedSubreddits?: string[]
  existingNegativeSubreddits?: string[]
  updatedNegativeSubreddits?: string[]
}): {
  subreddits: string[]
  negativeSubreddits: string[]
} {
  let subreddits = updatedSubreddits ?? existingSubreddits ?? []
  let negativeSubreddits =
    updatedNegativeSubreddits ?? existingNegativeSubreddits ?? []

  // Ensure subreddits are unique
  if (updatedSubreddits) {
    const lowerCaseSubreddits = updatedSubreddits.map(subreddit =>
      subreddit.toLowerCase()
    )
    subreddits = Array.from(new Set(lowerCaseSubreddits).values())
  }

  // Ensure negative subreddits are unique
  if (updatedNegativeSubreddits) {
    const lowerCaseNegativeSubreddits = updatedNegativeSubreddits.map(
      subreddit => subreddit.toLowerCase()
    )
    negativeSubreddits = Array.from(
      new Set(lowerCaseNegativeSubreddits).values()
    )
  }

  // Remove subreddits that are being added to negative subreddits
  const subredditsToRemove = updatedNegativeSubreddits?.filter(
    negativeSubreddit => existingSubreddits?.includes(negativeSubreddit)
  )
  if (subredditsToRemove?.length) {
    subreddits = _.without(subreddits, ...subredditsToRemove)
    console.log(
      'Removed subreddits that are being added negativeSubreddits %O',
      {
        subredditsToRemove,
        existingSubreddits,
        subreddits,
      }
    )
  }

  // Remove negative subreddits that are being added to subreddits
  const negativeSubredditsToRemove = updatedSubreddits?.filter(
    subreddit => existingNegativeSubreddits?.includes(subreddit)
  )
  if (negativeSubredditsToRemove?.length) {
    negativeSubreddits = _.without(
      negativeSubreddits,
      ...negativeSubredditsToRemove
    )
    console.log(
      'Removed negativeSubreddits that are being added subreddits %O',
      {
        negativeSubredditsToRemove,
        existingNegativeSubreddits,
        negativeSubreddits,
      }
    )
  }

  return {
    subreddits,
    negativeSubreddits,
  }
}

export const commentThreadContainsText = ({
  commentThread,
  text,
}: {
  commentThread: RedditCommentType
  text: string
}): boolean => {
  const bodyHasText = !!commentThread.body?.match(new RegExp(text, 'i'))?.length
  return (
    bodyHasText ||
    !!commentThread.replies?.some(reply =>
      commentThreadContainsText({ commentThread: reply, text })
    )
  )
}

export const shouldIncludeLinkInNewComment = ({
  post,
  commentThread,
  settings,
  shouldPromoteBusiness,
}: {
  post?: RedditPostType
  commentThread?: RedditCommentType
  settings: Settings | null
  shouldPromoteBusiness?: boolean
}) => {
  if (!shouldPromoteBusiness) {
    console.log(
      'Not including link in new comment because not promoting business'
    )
    return false
  }

  const { workCompany, workCompanyWebsite } = settings ?? {}
  const hasCompanyAndWebsite = !!workCompanyWebsite && !!workCompany
  if (!hasCompanyAndWebsite) {
    console.log(
      'Not including link in new comment because no company or website',
      {
        workCompany,
        workCompanyWebsite,
      }
    )
    return false
  }

  const lastComment = commentThread
    ? getLastCommentInThread(commentThread)
    : null
  const doesPostOrLastCommentMentionCompany = lastComment
    ? !!lastComment?.body?.match(new RegExp(workCompany, 'i'))?.length
    : post
    ? !!post.title.match(new RegExp(workCompany, 'i'))?.length
    : false
  if (!doesPostOrLastCommentMentionCompany) {
    console.log(
      'Not including link in new comment because post or last comment does not mention company',
      { post, commentThread, lastComment, workCompany, workCompanyWebsite }
    )
    return false
  }
  const websiteBaseUrl = getBaseUrl(workCompanyWebsite)
  const doesAlreadyIncludeCompanyWebsite = commentThread
    ? commentThreadContainsText({
        commentThread,
        text: websiteBaseUrl,
      })
    : post
    ? !!post.title.match(new RegExp(websiteBaseUrl, 'i'))?.length
    : false
  if (doesAlreadyIncludeCompanyWebsite) {
    console.log(
      'Not including link in new comment because already includes company website',
      { post, commentThread, lastComment, workCompany, workCompanyWebsite }
    )
    return false
  }

  console.log(
    'Including link in new comment because does not already include company website',
    { post, commentThread, lastComment, workCompany, workCompanyWebsite }
  )
  return true
}
