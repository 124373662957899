// @see https://www.reddit.com/dev/api

import dayjs from '@/shared/singletons/dayjs'
import type { FAQItem } from '@/web/components/FAQAccordion'
import {
  ActionType,
  ActivityType,
  type Action,
  type KeywordMatch,
  type KeywordMatchType,
} from '@prisma/client'
import { z } from 'zod'

export const SCRIPT_USER_AGENT = 'script:pulse:v1.0.0 (by /u/usepulseai)'

export type RedditUserType = {
  id: string // Unique identifier for the user
  name: string // Username of the user
  created: number // Account creation time in UTC
  link_karma: number // Link karma of the user
  comment_karma: number // Comment karma of the user
  is_gold: boolean // Indicates if the user has Reddit Gold
  is_mod: boolean // Indicates if the user is a moderator
  over_18: boolean // Indicates if the user is over 18
  verified: boolean // Indicates if the user is verified
  icon_img?: string // URL of the user's icon
  snoovatar_img?: string // URL of the user's snoovatar
}

export type RedditSubredditType = {
  display_name: string
  title: string
  subscribers: number
  public_description: string
  url: string
}

// Define the type for a Reddit post
export type RedditPostType = {
  author: string // Author of the post
  author_is_blocked?: boolean // Indicates if the author is blocked
  created_utc: number // Creation time in UTC
  crosspost_parent_list?: RedditPostType[] // List of crossposts
  id: string // Unique identifier for the post
  is_self: boolean // Indicates if the post is a self post
  is_video: boolean // Indicates if the post is a video
  num_comments: number // Number of comments on the post
  over_18: boolean // Indicates if the post is marked as NSFW
  score: number // Score of the post
  selftext?: string // Text content of the post (if any)
  spoiler: boolean // Indicates if the post is marked as a spoiler
  stickied: boolean // Indicates if the post is stickied
  subreddit_subscribers: number // Number of subscribers to the subreddit
  subreddit: string // Subreddit where the post was made
  thumbnail: string // URL of the post's thumbnail
  title: string // Title of the post
  url: string // URL of the post
}

// Define the type for a Reddit comment
export type RedditCommentType = {
  id: string // Unique identifier for the comment
  author: string // Author of the comment
  body?: string // Text content of the comment
  created_utc: number // Creation time in UTC
  score: number // Score of the comment
  parent_id: string // ID of the parent post or comment
  subreddit: string // Subreddit where the comment was made
  link_id?: string // ID of the post where the comment was made (in the format "t3_<post_id>")
  replies?: RedditCommentType[]
}

export type RedditRawMoreType = {
  count: number
  name: 't1__'
  id: '_'
  parent_id: string
  depth: number
  children: []
}

export type RedditRawCommentType = RedditCommentType & {
  replies?: {
    kind: 'Listing'
    data: {
      children: (
        | { kind: 't1'; data: RedditRawCommentType }
        | { kind: 'More'; data: RedditRawMoreType }
      )[]
    }
  }
}

export enum AlertFrequency {
  INSTANT = 'As soon as possible',
  DAILY = 'Once a day',
  // WEEKLY = 'Weekly'
  NEVER = 'Never',
}

// Define the schema for Reddit settings
export const RedditSettingsInput = z
  .object({
    subreddits: z.array(z.string()).optional(),
    negativeSubreddits: z.array(z.string()).optional(),
    keywords: z.array(z.string()).optional(),
    negativeUsers: z.array(z.string()).optional(),
    isPromotingBusiness: z.boolean().optional(),
    alertFrequency: z.nativeEnum(AlertFrequency).optional(),
    alertTime: z
      .string()
      .optional()
      .refine(
        val => {
          if (val === undefined) {
            return true
          }

          const date = dayjs(val, 'HH:mm A').toDate()
          const hours = date.getHours()
          const minutes = date.getMinutes()

          return (
            hours != null &&
            minutes != null &&
            hours >= 0 &&
            hours < 24 &&
            minutes >= 0 &&
            minutes < 60
          )
        },
        {
          message: 'Invalid time format',
        }
      ),
    lastAlertDate: z.date().optional(),
  })
  .optional()

export type RedditSettingsInputType = z.infer<typeof RedditSettingsInput>
export type RedditSettingsDBType =
  | (Omit<RedditSettingsInputType, 'lastAlertDate'> & {
      lastAlertDate?: string
    })
  | undefined

// Define the available Reddit action types
export const REDDIT_ACTION_TYPES = [
  ActionType.REPLY_TO_COMMENT,
  ActionType.REPLY_TO_POST,
]

// Define the scopes required for the Reddit API
export const REDDIT_SCOPES = [
  'identity', // used to get their username/id
  'read',
  'history', // used to fetch their comments
  // 'submit',
  // 'privatemessages',
  'mysubreddits', // used as default when searching for subreddits
]

export const REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA = {
  [ActionType.REPLY_TO_POST]: z.object({
    postId: z.string(),
    message: z.string().optional(),
    isPromotingBusiness: z.boolean().optional(),
    isPromotingBusinessReason: z.string().optional(),
  }),
  [ActionType.REPLY_TO_COMMENT]: z.object({
    postId: z.string(),
    commentId: z.string(),
    message: z.string().optional(),
    isPromotingBusiness: z.boolean().optional(),
    isPromotingBusinessReason: z.string().optional(),
  }),
} as const satisfies Partial<Record<ActionType, z.SomeZodObject>>
export type RedditReplyToPostParametersType = z.infer<
  (typeof REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.REPLY_TO_POST]
>
export type RedditReplyToCommentParametersType = z.infer<
  (typeof REDDIT_ACTION_TYPE_PARAMETERS_SCHEMA)[typeof ActionType.REPLY_TO_COMMENT]
>

export const REDDIT_FULLNAME_TYPE_PREFIXES = [
  't1',
  't2',
  't3',
  't4',
  't5',
  't6',
] as const
export type RedditFullnamePrefixType =
  (typeof REDDIT_FULLNAME_TYPE_PREFIXES)[number]
export type RedditThingType =
  | 'comment'
  | 'post'
  | 'subreddit'
  | 'award'
  | 'account'
  | 'message'

export const REDDIT_FULLNAME_TYPE: Record<
  RedditFullnamePrefixType,
  RedditThingType
> = {
  t1: 'comment',
  t2: 'account',
  t3: 'post',
  t4: 'message',
  t5: 'subreddit',
  t6: 'award',
}

export type RedditSearchResultType = {
  text: string
  keywords: string[]
} & (
  | {
      type: typeof KeywordMatchType.POST
      post: RedditPostType
    }
  | {
      type: typeof KeywordMatchType.COMMENT
      comment: RedditCommentType
    }
)

export type RedditKeywordMatchWithDataType =
  | (KeywordMatch & {
      type: typeof KeywordMatchType.POST
      post: RedditPostType | null
    })
  | (KeywordMatch & {
      type: typeof KeywordMatchType.COMMENT
      post: RedditPostType | null
      comment: RedditCommentType | null
    })

export type RedditNewPostCommentType = {
  post: RedditPostType
  comment: string
  tokenCount: number
}

export const MIN_POST_SCORE_TO_REPLY = 0
export const MIN_COMMENT_SCORE_TO_REPLY = 0
export const MIN_SUBREDDIT_SUBSCRIBERS_TO_REPLY = 100

export type RedditCommentThreadWithReplyType = {
  post: RedditPostType
  comment: RedditCommentType
  postAuthor: RedditUserType
  commentUsers: RedditUserType[]
  replyId: string
  action?: Action
}
export type RedditCommentThreadsWithReplyType =
  RedditCommentThreadWithReplyType[]

export enum RedditCommentList {
  Drafted = 'drafted',
  Sent = 'sent',
  Cancelled = 'cancelled',
}
export enum RedditReplyList {
  Inbox = 'inbox',
  Drafted = 'drafted',
  Sent = 'sent',
  Cancelled = 'cancelled',
}
export enum RedditKeywordsList {
  Inbox = 'inbox',
  Drafted = 'drafted',
  Sent = 'sent',
  Cancelled = 'cancelled',
}

export const REDDIT_ACTIVITY_TYPES = [
  ActivityType.KEYWORD_MATCH_PROCESSED,
  ActivityType.COMMENT_GENERATED,
  ActivityType.COMMENT_PROCESSED,
] as const

export const SUBREDDIT_RESTRICTIONS = {
  communitymanager: {
    minDays: 10,
  },
  productivity: {
    minDays: 1,
  },
  SocialMediaManagers: {
    minDays: 1,
  },
  PartneredYoutube: {
    minDays: 1, // TBD
  },
  InstagramMarketing: {
    minDays: 1, // TBD
  },
  ecommerce: {
    minKarma: 10,
    minDays: 10,
  },
  shopify: {
    minDays: 10,
  },
  CryptoCurrency: {
    minKarma: 50,
    minDays: 30,
  },
  Conspiracy: {
    minDays: 60,
  },
  venezuela: {
    minDays: 30,
  },
}

export const REDDIT_LANDING_FAQ: FAQItem[] = [
  {
    question: 'How does Pulse work?',
    answer:
      'Pulse notifies you whenever a relevant post or comment containing your specified keywords appears on Reddit. It generates a spam-proof, pre-drafted comment for you to review and post, helping you engage effectively with your target audience. You can also draft comments to increase your karma in your chosen subreddits.',
  },
  {
    question: 'What is Reddit karma, and why is it important?',
    answer:
      'Karma represents the points you earn when Reddit users upvote your posts and comments. Higher karma increases your credibility, positions your responses higher in discussions, and grants you access to participate in more subreddits (many require a minimum karma, usually around 100). Building karma organically can take weeks, but with Pulse, you can accelerate this process significantly.',
  },
  {
    question: 'How do I post the comments generated by Pulse?',
    answer:
      'Pulse makes it easy to engage with relevant Reddit discussions. When a comment is generated, simply click once to copy it. Pulse will then redirect you to the specific Reddit post or comment where you can paste your reply and click "Comment." You retain full control over what gets posted.',
  },
  {
    question: 'Will using Pulse get my Reddit account banned for spamming?',
    answer:
      'No, Pulse is designed to help you engage meaningfully without violating Reddit’s guidelines. It does not post on your behalf; instead, it provides you with relevant opportunities and suggested comments. By focusing on valuable interactions and avoiding excessive posting, you minimize the risk of being flagged for spam. We recommend building up your karma before promoting your business directly.',
  },
  {
    question: 'Why should I use Reddit to build my brand?',
    answer:
      'Reddit hosts a vast and active user base within highly targeted communities (subreddits). It’s a goldmine for organic engagement, which often outperforms paid advertising in visibility and trust. Moreover, Reddit content influences AI models like OpenAI’s, extending your brand’s reach beyond the platform itself.',
  },
  {
    question: 'Can I use Pulse with multiple Reddit accounts?',
    answer:
      'Currently, Pulse supports one Reddit account per user. We are actively working on adding multi-account support in future updates. If you’re interested in this feature, please contact us to express your interest.',
  },
  {
    question: 'Is customer support available if I need help?',
    answer:
      'Yes, we offer customer support to assist you with any questions or issues. Our team is committed to helping you get the most out of Pulse. Friendly inquiries are always welcome!',
  },
  {
    question: 'Can I cancel my Pulse subscription at any time?',
    answer:
      'Absolutely! You can cancel your subscription at any time without any cancellation fees. Simply go to your account settings to manage your subscription.',
  },
]
export const REDDIT_COMMENT_PROMOTION_WARNING =
  'This comment promotes your business and should be reviewed.'
