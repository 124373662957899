import { createPatch } from 'diff'
import DOMPurify from 'dompurify'
import he from 'he'
import juice from 'juice'
import _ from 'lodash'
import sanitizeHtmlLib from 'sanitize-html'
import showdown from 'showdown'
import TurndownService from 'turndown'

// CONVERSION //

showdown.extension('no-paragraphs', function () {
  return [
    {
      type: 'output',
      filter: function (text) {
        // remove paragraphs
        text = text.replace(/<\/?p[^>]*>/g, '').trim()

        return text
      },
    },
  ]
})

export function convertTextToHtml(text: string) {
  const html = stripHtml(text).replace(/(\r\n|\n|\r)/gm, '<br />')

  console.log('Converted text to html %O', {
    text,
    html,
  })

  return html
}

export function convertMarkdownToHtml(
  message: string,
  options?: ConstructorParameters<typeof showdown.Converter>[0]
) {
  const converter = new showdown.Converter({
    // extensions: ['no-paragraphs'],
    simpleLineBreaks: true,
    simplifiedAutoLink: true,
    emoji: true,
    openLinksInNewWindow: true,
    disableForced4SpacesIndentedSublists: true,
    tables: true,
    ...options,
  })
  return trimHtml(converter.makeHtml(trimHtml(message)))

  // const placeholder = '1234567890qwertyuiopasdfghjklzxcvbnm'

  // const preprocessedMarkdown = message
  //   // // Only convert 2+ newlines to <br> to prevent messing up lists
  //   .replaceAll(/(\r\n|\n|\r){2,}/gm, `\n${placeholder}\n`)
  //   // Add lines between <br> and non-tags
  //   .replaceAll(/<br \/?>([^<])/gm, '\n<br />\n$1')
  //   .trim()

  // const html = converter.makeHtml(preprocessedMarkdown)

  // const postprocessedHtml = html
  // Replace remaining newlines after non-tags to <br> post-conversion
  // .replaceAll(/([^>])[\r\n|\n|\r]/gm, '$1<br />\n')
  // .replaceAll(
  //   new RegExp(`(<br \/>)?\s*${placeholder}\s*(<br \/>)?`, 'gm'),
  //   '<br /><br />'
  // )
  // Replace double newlines with <br>
  // .replace(/(<br \/>\s*)}{2,}/gm, '<br />')

  // const finalHtml = trimHtml(postprocessedHtml)

  // console.log('Converted markdown to html %O', {
  //   message,
  //   preprocessedMarkdown,
  //   html,
  //   postprocessedHtml,
  //   finalHtml,
  // })
  // return finalHtml
}

export function convertHtmlToMarkdown(html: string) {
  const turndownService = new TurndownService()
  turndownService.remove(['head', 'style', 'script', 'meta'])
  return turndownService.turndown(html)
}

// STRIPPING //

export function stripHtml(html: string): string {
  // Decode first to strip html
  const decodedHtml = he.decode(html)
  console.log('Decoded html %o', decodedHtml.substring(0, 100) + '...')

  const sanitizedHtml = sanitizeHtmlLib(decodedHtml, {
    allowedTags: [], // No tags are allowed, effectively stripping out all HTML
    allowedAttributes: {}, // No attributes are allowed
  })
  console.log('Stripped html %o', sanitizedHtml.substring(0, 100) + '...')

  // Decode again because html is re-encoded
  const decodedSanitizedHtml = he.decode(sanitizedHtml)
  console.log('Decoded html %o', decodedSanitizedHtml.substring(0, 100) + '...')

  return decodedSanitizedHtml
}

export function sanitizeAndPreserveHtml(html: string): string {
  console.log('Sanitizing html %o', html.substring(0, 100) + '...')
  const unescapedHtml = html.replace(/\\r\\n/g, '').replace(/\\"/g, '"')

  try {
    const inlineStyledHtml = juice(unescapedHtml, {
      removeStyleTags: true,
      preserveImportant: true,
      preserveFontFaces: true,
    })

    const inlineStylesDiff = createPatch(
      'inline styles',
      html,
      inlineStyledHtml,
      '',
      '',
      {}
    )
    console.log(inlineStylesDiff)

    const sanitizedHtml = sanitizeHtmlLib(inlineStyledHtml, {
      allowedTags: sanitizeHtmlLib.defaults.allowedTags.concat(['img', 'br']),
      parseStyleAttributes: false,
      allowedAttributes: false,
    })

    const sanitizedDiff = createPatch(
      'sanitized',
      inlineStyledHtml,
      sanitizedHtml,
      '',
      '',
      {}
    )
    console.log(sanitizedDiff)

    console.log('Sanitized html %o', sanitizedHtml.substring(0, 100) + '...')

    return sanitizedHtml
  } catch (e) {
    console.error(e)
    return html
  }
}

export function sanitizeHtml(
  html: string,
  { isAllowingImages = true }: { isAllowingImages: boolean }
): string {
  const config = {
    USE_PROFILES: {
      html: true,
    },
    RETURN_DOM_FRAGMENT: false,
    RETURN_DOM: false,
    FORBID_TAGS: isAllowingImages ? [] : ['img'],
  } as const

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    }
  })

  return DOMPurify.sanitize(html, config)
}

// MATCHING //

export function wrapMatches({
  html,
  searchQuery,
  tagName = 'b',
  className = '',
}: {
  html: string
  searchQuery: string
  tagName?: string
  className?: string
}) {
  return html.replace(
    new RegExp(_.escapeRegExp(searchQuery), 'gi'),
    `<${tagName} class="${className}">$&</${tagName}>`
  )
}

// TRIMMING //

export function trimHtml(html: string) {
  return (
    html
      .trim()
      // Trim empty characters at the beginning/end of the string
      .replace(/^(\&amp\;\#x200B\;|\&\#x200B\;|\&nbsp\;)+/, '')
      .replace(/(\&amp\;\#x200B\;|\&\#x200B\;|\&nbsp\;)+$/, '')
      .replace(/^(<br \/>)+/, '')
      .replace(/(<br \/>)+$/, '')
  )
}
