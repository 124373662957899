import { cva, type VariantProps } from 'class-variance-authority'
import Link from 'next/link'
import React from 'react'
import { cn } from '../libs/utils'

const rootVariant = cva(
  'transition-all text-primary px-0 h-auto break-all rounded-none min-w-0 inline break-words',
  {
    variants: {
      variant: {
        underline: 'border-b',
        ghost: '',

        // @deprecated
        premium:
          'text-gradient hover:border-b-primary border-b border-b-transparent',
        primary: 'text-primary hover:text-primary/50 duration-200',
        secondary: 'text-grey-darkest hover:text-primary duration-200',
        light: 'text-grey hover:text-primary duration-200',
      },
      theme: {
        premium: 'border-b border-b-transparent',
        primary: 'text-primary hover:text-primary/50 duration-200',
        // used in <Footer>
        secondary: 'border-transparent text-muted-foreground hover:text-black',
        dark: 'border-black text-black hover:opacity-50',
        light: 'text-grey hover:text-primary duration-200',
      },
      size: {
        sm: 'text-sm leading-2!',
        xs: 'text-xs',
      },
    },
    defaultVariants: {
      variant: 'ghost',
      theme: 'secondary',
    },
    compoundVariants: [
      {
        variant: 'ghost',
        className: 'border-none hover:border-none',
      },
      {
        variant: 'ghost',
        theme: 'premium',
        className: 'text-gradient hover:text-black',
      },
      {
        variant: 'ghost',
        theme: 'primary',
        className: 'text-primary hover:text-primary/50',
      },
      {
        variant: 'ghost',
        theme: 'secondary',
        className: 'text-secondary-foreground hover:text-primary',
      },
      {
        variant: 'underline',
        theme: 'premium',
        className: 'underline-gradient',
      },
      {
        variant: 'underline',
        theme: 'premium',
        size: 'sm',
        className: 'underline-gradient-xs',
      },
      {
        variant: 'underline',
        theme: 'premium',
        size: 'xs',
        className: 'underline-gradient-xs',
      },
      {
        variant: 'underline',
        theme: 'primary',
        className: 'border-primary',
      },
      {
        variant: 'underline',
        theme: 'secondary',
        className: 'underline-gradient',
      },
      {
        variant: 'underline',
        theme: 'secondary',
        size: 'sm',
        className: 'underline-gradient-xs',
      },
      {
        variant: 'underline',
        theme: 'secondary',
        size: 'xs',
        className: 'underline-gradient-xs',
      },
      // {
      //   variant: 'underline',
      //   theme: 'dark',
      //   className: 'underline',
      // },
    ],
  }
)

interface CustomLinkProps
  extends Omit<React.ComponentProps<typeof Link>, 'href'>,
    VariantProps<typeof rootVariant> {
  children: React.ReactNode
  className?: string
  href?: React.ComponentProps<typeof Link>['href']
}

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  className,
  variant,
  href,
  theme,
  size,
  ...props
}) => {
  if (!href)
    return (
      <span className={cn(rootVariant({ variant, theme, size }), className)}>
        {children}
      </span>
    )

  return (
    <Link
      {...props}
      href={href}
      className={cn(rootVariant({ variant, theme, size }), className)}>
      {children}
    </Link>
  )
}

export { CustomLink as Link }
