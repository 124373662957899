import { RedditIcon } from '@/web/components/icons/RedditIcon'
import { Button } from '@/web/components/ui/button'
import { ROUTE } from '@/web/constants/router'
import Link from 'next/link'

export function RedditSettings() {
  return (
    <section className='flex flex-col gap-4'>
      <div className='flex flex-col gap-1'>
        <h6>What are you waiting for?</h6>
        <p className='text-sm text-muted-foreground text-balance'>
          Now that your Reddit integration is enabled, you have access to the
          Reddit Dashboard. You can always find it by clicking on the{' '}
          <Link href={ROUTE.REDDIT_DASHBOARD}>
            <Button
              variant='sidebar'
              startIcon={<RedditIcon className='size-4 inline' />}
              className='inline-flex align-middle w-fit'>
              Reddit
            </Button>
          </Link>{' '}
          button in the navigation menu.
        </p>
      </div>
      <Link href={ROUTE.REDDIT_DASHBOARD}>
        <Button theme='premium' size='xl'>
          Go to the Reddit Dashboard
        </Button>
      </Link>
    </section>
  )
}
