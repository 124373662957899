import { ROOT_UNAUTHENTICATED_ROUTE_INTEGRATION_MAP } from '@/web/constants/router'
import { useRootRoute } from '@/web/hooks/useRootRoute'
import { IntegrationName } from '@prisma/client'

export const usePrimaryIntegration = () => {
  const rootUnauthenticatedRoute = useRootRoute({
    type: 'unauthenticated',
  })
  if (!rootUnauthenticatedRoute) {
    return IntegrationName.REDDIT
  }

  return (
    ROOT_UNAUTHENTICATED_ROUTE_INTEGRATION_MAP[rootUnauthenticatedRoute] ??
    IntegrationName.REDDIT
  )
}
