import { FeatureType, PRICING_FEATURE_DATA } from '@/shared/constants/pricing'
import {
  REDDIT_ACTIVITY_TYPES,
  type RedditSettingsInputType,
} from '@/shared/constants/reddit'
import { getFeatureData } from '@/shared/utils/pricing'
import { ActivityUsageProgressBar } from '@/web/components/ActivityUsageProgressBar'
import { Link } from '@/web/components/Link'
import { Button } from '@/web/components/ui/button'
import { UsageProgressBar } from '@/web/components/UsageProgressBar'
import { ROUTE } from '@/web/constants/router'
import { useIntegration } from '@/web/hooks/use-integration'
import { useActivityOrFeatureLimit } from '@/web/hooks/useActivityOrFeatureLimit'
import { useMaxActivityUsage } from '@/web/hooks/useMaxActivityUsage'
import { IntegrationName } from '@prisma/client'
import NextLink from 'next/link'

export const RedditSidebarActivity = () => {
  const { integration: redditIntegration } = useIntegration(
    IntegrationName.REDDIT
  )
  const redditSettings = redditIntegration?.settings as RedditSettingsInputType

  const { limit: keywordsLimit, isLoading: isKeywordsLimitLoading } =
    useActivityOrFeatureLimit(FeatureType.KEYWORDS_MONITORED)

  const maxActivityUsage = useMaxActivityUsage(REDDIT_ACTIVITY_TYPES)

  if (!redditIntegration?.isEnabled) return null

  return (
    <div className='mt-auto flex flex-col p-2 gap-4 shrink-0'>
      <UsageProgressBar
        info={PRICING_FEATURE_DATA.KEYWORDS_MONITORED.description}
        current={redditSettings?.keywords?.length ?? 0}
        limit={
          keywordsLimit && 'count' in keywordsLimit ? keywordsLimit.count : null
        }
        title='Keywords Monitored'
        isLoading={isKeywordsLimitLoading}
        size='sm'
      />
      {REDDIT_ACTIVITY_TYPES.map(activityType => (
        <ActivityUsageProgressBar
          key={activityType}
          activityType={activityType}
          integrationName={IntegrationName.REDDIT}
          size='sm'
        />
      ))}

      <div className='p-1 flex flex-col gap-2 items-center'>
        {maxActivityUsage && maxActivityUsage.progress > 0.5 ? (
          <>
            <small className='text-center'>
              You’ve used{' '}
              {maxActivityUsage?.progress >= 1
                ? 'all'
                : Math.round(maxActivityUsage.progress * 100) + '%'}{' '}
              of your{' '}
              <strong className='whitespace-nowrap'>
                {
                  getFeatureData(
                    maxActivityUsage.activityType,
                    IntegrationName.REDDIT
                  )?.name
                }
              </strong>
              .
            </small>
            <NextLink href={ROUTE.SETTINGS_SUBSCRIPTION_CHANGE_PLAN}>
              <Button theme='premium'>Upgrade your plan</Button>
            </NextLink>
          </>
        ) : (
          <Link
            href={ROUTE.SETTINGS_SUBSCRIPTION_CHANGE_PLAN}
            variant='underline'
            theme='secondary'
            size='xs'>
            Manage your plan
          </Link>
        )}
      </div>
    </div>
  )
}
