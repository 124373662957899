import { cn } from '@/web/libs/utils.ts'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-lg font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25 gap-2 active:opacity-75 text-left cursor-pointer flex-nowrap min-w-fit',
  {
    variants: {
      size: {
        default: 'px-8 py-2 text-md font-semibold tracking-wide',
        md: 'px-8 py-2 text-md font-semibold tracking-wide',
        none: '',
        '2xs': 'px-2 py-0.5 text-2xs',
        xs: 'px-3 py-1 text-xs',
        sm: 'px-4 py-1.5 text-sm tracking-wide',
        'sm-wide': 'px-8 py-1.5 text-sm tracking-wide',
        lg: 'px-8 text-lg',
        xl: 'h-[3.4rem] px-8 xl:px-16 py-4 text-lg text-center w-full sm:w-auto',
        normal: 'px-3 py-1.5',
        wide: 'px-8 py-2',
        xwide: 'px-4 py-2 w-96',
        circle: 'p-2 rounded-full',
      },
      variant: {
        default: '', // 'border-1',
        premium: '',
        ghost:
          '[&]:hover:bg-accent [&]:hover:text-accent-foreground border !border-transparent',
        outline: 'border',
        link: 'text-primary border-b [&]:border-transparent px-0 h-auto break-all rounded-none min-w-0 inline break-words',
        navbar: 'text-secondary-foreground [&]:hover:text-gradient',
        sidebar:
          'group items-center flex justify-start gap-x-3 px-4 text-sm font-semibold cursor-pointer transition-colors text-neutral-700 [&]:hover:text-primary [&]:hover:bg-base-300 w-full h-[40px] py-0 min-w-0',
      },
      theme: {
        default: 'border-primary hover:border-primary',
        primary: 'border-primary hover:border-primary',
        secondary: 'border-input hover:border-input',
        'secondary-dark': 'border-grey-dark hover:border-grey-dark',
        success: 'border-success hover:border-success',
        warning: 'border-warning hover:border-warning',
        destructive: 'border-destructive hover:border-destructive',
        premium: 'border-premium hover:border-premium',
        transparent: 'border-transparent hover:border-transparent',
        inverted:
          'bg-white hover:bg-white/90 text-white border-white hover:border-white/90 hover:text-white/90',
      },
      state: {
        active: 'ring-2 ring-ring z-1 relative',
        inactive: '',
        loading: 'not(disabled):animate-pulse',
        selected: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      theme: 'primary',
    },
    compoundVariants: [
      {
        variant: 'default',
        theme: 'primary',
        className: 'bg-primary text-primary-foreground [&]:hover:bg-primary/90',
      },
      {
        variant: 'default',
        theme: 'default',
        className: 'bg-primary text-primary-foreground [&]:hover:bg-primary/90',
      },
      {
        variant: 'default',
        theme: 'secondary',
        className:
          'bg-secondary text-secondary-foreground [&]:hover:bg-secondary/10 [&]:hover:shadow-sm',
      },
      {
        variant: 'default',
        theme: 'secondary-dark',
        className:
          'bg-base-300 text-secondary-foreground [&]:hover:bg-white [&]:hover:shadow-sm',
      },
      {
        variant: 'default',
        theme: 'premium',
        className: 'text-primary-foreground bg-gradient [&]:hover:opacity-50',
      },
      {
        variant: 'default',
        theme: 'success',
        className: 'bg-success text-success-content [&]:hover:bg-success/90',
      },
      {
        variant: 'default',
        theme: 'warning',
        className: 'bg-warning text-warning-foreground [&]:hover:bg-warning/90',
      },
      {
        variant: 'default',
        theme: 'destructive',
        className:
          'bg-destructive text-destructive-foreground [&]:hover:bg-destructive/90',
      },
      {
        variant: 'outline',
        theme: 'primary',
        className:
          'relative text-black [&]:hover:opacity-50 outline-gradient border-transparent',
      },
      {
        variant: 'outline',
        theme: 'secondary',
        className:
          'border border-input bg-background [&]:hover:bg-accent [&]:hover:text-accent-foreground',
      },
      {
        variant: 'outline',
        theme: 'transparent',
        className: 'border border-accent-foreground [&]:hover:bg-accent/75',
      },
      {
        variant: 'outline',
        theme: 'success',
        className: 'border-success text-success [&]:hover:opacity-50',
      },
      {
        variant: 'outline',
        theme: 'warning',
        className: 'border-warning text-warning [&]:hover:opacity-50',
      },
      {
        variant: 'outline',
        theme: 'destructive',
        className: 'border-destructive text-destructive [&]:hover:opacity-50',
      },
      {
        variant: 'outline',
        theme: 'inverted',
        className:
          'border-white text-white bg-transparent [&]:hover:bg-transparent [&]:hover:opacity-50',
      },
      {
        variant: 'ghost',
        theme: 'premium',
        className:
          '[&:not(:hover)]:text-gradient [&]:hover:bg-primary/10 [&]:hover:text-primary border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'primary',
        className:
          'text-primary [&]:hover:bg-primary/10 [&]:hover:text-primary border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'primary',
        state: 'active',
        className: 'ring-primary',
      },
      {
        variant: 'ghost',
        theme: 'primary',
        state: 'selected',
        className: 'bg-accent',
      },
      {
        variant: 'ghost',
        theme: 'secondary-dark',
        className:
          'text-secondary-foreground [&]:hover:bg-white border border-transparent hover:border-border border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'transparent',
        className:
          'opacity-50 [&]:hover:bg-transparent [&]:hover:opacity-100 border border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'secondary',
        state: 'selected',
        className: 'text-secondary-foreground border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'secondary',
        state: 'active',
        className: 'text-secondary-foreground ring-secondary',
      },
      {
        variant: 'ghost',
        theme: 'success',
        className:
          'text-muted-foreground [&]:hover:text-success border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'success',
        state: 'selected',
        className: 'text-success',
      },
      {
        variant: 'ghost',
        theme: 'success',
        state: 'active',
        className: 'text-success ring-success',
      },
      {
        variant: 'ghost',
        theme: 'warning',
        className:
          'text-muted-foreground [&]:hover:text-warning [&_*]:hover:text-warning border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'warning',
        state: 'selected',
        className: 'text-warning',
      },
      {
        variant: 'ghost',
        theme: 'warning',
        state: 'active',
        className: 'text-warning ring-warning',
      },
      {
        variant: 'ghost',
        theme: 'destructive',
        className:
          'text-muted-foreground [&]:hover:text-destructive [&_*]:hover:text-destructive border-transparent',
      },
      {
        variant: 'ghost',
        theme: 'destructive',
        state: 'selected',
        className: 'text-destructive',
      },
      {
        variant: 'ghost',
        theme: 'destructive',
        state: 'active',
        className: 'text-destructive ring-destructive',
      },
      {
        variant: 'link',
        theme: 'premium',
        className:
          'text-gradient hover:underline-gradient hover:border-transparent hover:text-black',
      },
      {
        variant: 'link',
        theme: 'primary',
        className: 'underline-gradient hover:border-transparent text-black',
      },
      {
        variant: 'link',
        theme: 'primary',
        size: 'sm',
        className: 'underline-gradient-sm',
      },
      {
        variant: 'link',
        theme: 'primary',
        size: 'xs',
        className: 'underline-gradient-xs',
      },
      {
        variant: 'link',
        theme: 'primary',
        size: 'none',
        className: 'underline-gradient-xs',
      },
      {
        variant: 'link',
        theme: 'secondary',
        className: 'text-muted-foreground hover:text-secondary-foreground',
      },
      {
        variant: 'link',
        theme: 'success',
        className: 'text-success hover:text-success',
      },
      {
        variant: 'link',
        theme: 'destructive',
        className: 'text-destructive hover:text-destructive',
      },
      {
        variant: 'link',
        className: 'px-0 py-0',
      },
      {
        variant: 'navbar',
        state: 'active',
        className: 'text-gradient ring-0',
      },
      {
        variant: 'sidebar',
        state: 'selected',
        className: 'bg-base-300 text-primary',
      },
      // State
      // {
      //   state: 'active',
      //   theme: 'secondary',
      //   className: 'border-primary',
      // },
    ],
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  className?: string
  startIcon?: JSX.Element | null
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, theme, state, children, startIcon, ...props },
    ref
  ) => {
    const buttonClass = cn(
      buttonVariants({ variant, size, state, theme }),
      startIcon && 'flex gap-2 items-center',
      startIcon && size === 'xl' && 'gap-4',
      className
    )

    const Element = variant === 'link' ? 'span' : 'button'

    return (
      <Element className={buttonClass} ref={ref} {...props}>
        <>
          {startIcon ? (
            <div className={cn('flex h-full items-center')}>{startIcon}</div>
          ) : undefined}
          {children}
        </>
      </Element>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
