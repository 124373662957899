import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'
import { type IntegrationName } from '@prisma/client'

export const useIntegration = (name: IntegrationName) => {
  const integrationsQueryResult = useIntegrationListQueryResult()
  const integrations = integrationsQueryResult.data
  const integration =
    integrations?.find(integration => integration.name === name) ?? null

  return { integration, isLoading: integrationsQueryResult.isInitialLoading }
}
