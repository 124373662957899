export function getProgressTheme(
  progress: number | null
): 'primary' | 'success' | 'warning' | 'error' {
  return progress === null
    ? 'primary'
    : progress >= 1
    ? 'error'
    : progress >= 0.5
    ? 'warning'
    : 'success'
}
