'use client'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import { cn } from '@/web/libs/utils'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

const TouchContext = React.createContext<boolean | undefined>(undefined)
const useTouch = () => React.useContext(TouchContext)
export const TouchProvider = (props: React.PropsWithChildren) => {
  const [isTouch, setIsTouch] = React.useState<boolean>(false)

  React.useEffect(() => {
    setIsTouch(window.matchMedia('(pointer: coarse)').matches)
  }, [])

  return <TouchContext.Provider value={isTouch} {...props} />
}

const TooltipProvider = (
  props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>
) => <TooltipPrimitive.Provider delayDuration={0} {...props} />

const Tooltip = (
  props: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>
) =>
  useTouch() ? <Popover {...props} /> : <TooltipPrimitive.Root {...props} />

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ className, ...props }, ref) =>
  useTouch() ? (
    <PopoverTrigger ref={ref} {...props} />
  ) : (
    <TooltipPrimitive.Trigger ref={ref} {...props} />
  )
)
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    portalProps?: React.ComponentProps<typeof TooltipPrimitive.Portal>
  }
>(({ className, sideOffset = 4, portalProps, ...props }, ref) =>
  useTouch() ? (
    <PopoverContent {...props} />
  ) : (
    <TooltipPrimitive.Portal {...portalProps}>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
          'bg-popover text-popover-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 max-w-sm rounded-sm border px-3 py-1.5 text-sm shadow-md shadow-grey-darker md:max-w-md xl:max-w-xl',
          className
        )}
        {...props}
      />
    </TooltipPrimitive.Portal>
  )
)
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger }
