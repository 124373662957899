import { Logo } from '@/web/components/Logo'
import { NavButtonList } from '@/web/components/NavButtonList'
import { RedditSidebarActivity } from '@/web/components/RedditSidebarActivity'
import { Button } from '@/web/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import { UserAvatar } from '@/web/components/UserAvatar'
import { useHasSidebar } from '@/web/hooks/use-has-sidebar'
import { useSidebarItems } from '@/web/hooks/useSidebarItems'
import { cn } from '@/web/libs/utils'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { ChevronsLeft } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import useLocalStorageState from 'use-local-storage-state'

export const DesktopSidebar = () => {
  const session = useSession()

  const [isOpen, setIsOpen] = useLocalStorageState('sidebar-open', {
    defaultValue: true,
  })

  const sidebarItems = useSidebarItems()

  const hasSidebar = useHasSidebar()
  if (!hasSidebar) {
    return null
  }

  return (
    <div
      className={cn(
        'hidden h-full overflow-y-auto border-r border-gray-200 bg-white py-2 px-2 transition-all duration-300 ease-in-out lg:flex flex-col gap-y-8',
        isOpen ? 'w-64' : 'w-[57px]'
      )}>
      <div
        className={cn(
          'flex h-16 shrink-0 items-center justify-between',
          !isOpen && 'px-0'
        )}>
        {isOpen ? <Logo size='sm' /> : null}
        <Button
          variant='sidebar'
          onClick={() => setIsOpen(!isOpen)}
          className='size-10 justify-center p-0'>
          {isOpen ? (
            <ChevronsLeft className='size-4 shrink-0' />
          ) : (
            <Bars3Icon className='size-4 shrink-0' />
          )}
        </Button>
      </div>
      <NavButtonList
        items={sidebarItems}
        isOpen={isOpen}
        // isShowingChildren={false}
      />

      {isOpen && <RedditSidebarActivity />}

      <Popover>
        <PopoverTrigger asChild className='mt-auto shrink-0'>
          <Button variant='sidebar' className={cn(!isOpen && 'justify-center')}>
            <UserAvatar user={session.data?.user} size='sm' />
            {isOpen
              ? session.data?.user.name ?? session.data?.user.email
              : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-64'>
          <Button
            theme='destructive'
            className='w-full'
            onClick={() => signOut()}>
            Sign out
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  )
}
