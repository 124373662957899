import { Button } from '@/web/components/ui/button'
import { cva, type VariantProps } from 'class-variance-authority'
import { TriangleAlertIcon, XIcon } from 'lucide-react'
import { useState, type PropsWithChildren } from 'react'

const rootVariant = cva(
  'flex w-full items-center justify-between gap-4 px-4 py-2 text-center',
  {
    variants: {
      theme: {
        destructive: 'bg-error text-error-content/75',
      },
    },
    defaultVariants: {
      theme: 'destructive',
    },
  }
)

export const TopBar = ({
  theme,
  children,
}: PropsWithChildren<VariantProps<typeof rootVariant>>) => {
  const [isClosed, setIsClosed] = useState(false)

  if (isClosed) return null

  return (
    <div className={rootVariant({ theme })}>
      <div className='flex flex-grow items-center justify-center gap-4'>
        <TriangleAlertIcon className='flex-shrink-0 opacity-25' />
        <p>{children}</p>
      </div>
      <Button
        variant='ghost'
        size='circle'
        theme='transparent'
        onClick={() => setIsClosed(true)}
        className='ml-auto'>
        <XIcon className='size-4' />
      </Button>
    </div>
  )
}
