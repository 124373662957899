'use client'

import { cn } from '@/web/libs/utils'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const wrapperVariants = cva(
  'bg-base-300 relative w-full overflow-hidden rounded-full',
  {
    variants: {
      size: {
        sm: 'h-1',
        md: 'h-4',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

const rootVariants = cva('bg-primary h-full w-full flex-1 transition-all', {
  variants: {
    theme: {
      premium: 'bg-gradient',
      primary: 'bg-primary',
      secondary: 'bg-secondary',
      accent: 'bg-accent',
      info: 'bg-info',
      success: 'bg-success',
      warning: 'bg-warning',
      error: 'bg-error',
    },
  },
  defaultVariants: {
    theme: 'primary',
  },
})

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> &
    VariantProps<typeof rootVariants> &
    VariantProps<typeof wrapperVariants>
>(({ className, value, theme, size, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(wrapperVariants({ size }), className)}
    {...props}>
    <ProgressPrimitive.Indicator
      className={cn(rootVariants({ theme }))}
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
