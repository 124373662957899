import { ROUTE } from '@/web/constants/router'
import { useHasIntegrationWarning } from '@/web/hooks/use-has-integration-warning'
import { useIsAdmin } from '@/web/hooks/use-is-admin'
import {
  BellIcon,
  Bug,
  Calendar,
  CheckCircle,
  CreditCard,
  Gauge,
  Home,
  HomeIcon,
  KeyRound,
  Link,
  MessageSquareIcon,
  MessagesSquareIcon,
  Search,
  Settings,
  SlidersHorizontalIcon,
  User2,
  type LucideIcon,
} from 'lucide-react'
import { type Route } from 'next'
import { type SVGProps } from 'react'
import { RedditIcon } from '../components/icons/RedditIcon'
import { YoutubeIcon } from '../components/icons/YoutubeIcon'

export type NavbarItemType = {
  name: string
  id: string
}
export const NAVBAR_ITEMS = [
  {
    name: 'How It Works',
    id: 'how-it-works',
  },
  {
    name: 'Testimonials',
    id: 'testimonials',
  },
  {
    name: 'Features',
    id: 'features',
  },
  {
    name: 'Pricing',
    id: 'pricing',
  },
  {
    name: 'Security',
    id: 'security',
  },
  {
    name: 'FAQ',
    id: 'faq',
  },
] as const satisfies NavbarItemType[]
// NOTE: Not all the ids exist so that when the user scrolls to a different section, it marks the existing one as inactive (ie. the hero section)
export const NAVBAR_ACTIVE_SECTION_IDS = [
  'hero',
  ...NAVBAR_ITEMS.map(item => item.id),
  'get-started',
] as const
export type NavbarActiveSectionId = (typeof NAVBAR_ACTIVE_SECTION_IDS)[number]
export const ROUTE_NAVBAR_ITEMS: Partial<Record<Route, NavbarItemType[]>> = {
  [ROUTE.GMAIL]: NAVBAR_ITEMS.filter(item => item.id !== 'faq'),
  [ROUTE.REDDIT]: NAVBAR_ITEMS.filter(item => item.id !== 'security'),
  [ROUTE.REDDIT_OLD]: NAVBAR_ITEMS.filter(item => item.id !== 'security'),
}

export type NavigationItemType = {
  name: string
  href: Route
  basePath?: string
  icon?: LucideIcon | React.ComponentType<SVGProps<SVGSVGElement>>
  useBadgeHook?: () => number | null
  useWarningHook?: () => string | boolean | null
  useVisibleHook?: () => boolean | null
  children?: Omit<NavigationItemType, 'children'>[]
}

export const NAVIGATION_SETTINGS_SIDEBAR_ITEMS: NavigationItemType[] = [
  { href: '/settings/profile', icon: User2, name: 'Profile' },
  // { href: '/settings/team' as Route, icon: Users2, name: 'Team' },
  {
    href: '/settings/change-password',
    icon: KeyRound,
    name: 'Change Password',
  },
  {
    href: '/settings/subscription',
    icon: CreditCard,
    name: 'Subscription',
  },
  {
    href: '/settings/usage',
    icon: Gauge,
    name: 'Usage',
  },
  {
    href: '/settings/admin',
    icon: Bug,
    name: 'Admin',
    useVisibleHook: useIsAdmin,
  },
]

const REDDIT_DASHBOARD_NAVIGATION_ITEM: NavigationItemType = {
  href: ROUTE.REDDIT_DASHBOARD,
  icon: HomeIcon,
  name: 'Dashboard',
}
export const NAVIGATION_REDDIT_NO_INTEGRATION_SIDEBAR_ITEMS: NavigationItemType[] =
  [REDDIT_DASHBOARD_NAVIGATION_ITEM]
export const NAVIGATION_REDDIT_SIDEBAR_ITEMS: NavigationItemType[] = [
  REDDIT_DASHBOARD_NAVIGATION_ITEM,
  { href: ROUTE.REDDIT_COMMENTS, icon: MessageSquareIcon, name: 'Comments' },
  { href: ROUTE.REDDIT_REPLIES, icon: MessagesSquareIcon, name: 'Replies' },
  { href: ROUTE.REDDIT_KEYWORDS, icon: BellIcon, name: 'Keyword Matches' },
  {
    href: ROUTE.REDDIT_SETTINGS,
    icon: SlidersHorizontalIcon,
    name: 'Configuration',
  },
]

export const REDDIT_NO_INTEGRATION_SIDEBAR_ITEMS: NavigationItemType[] = [
  {
    name: 'Reddit',
    href: ROUTE.REDDIT_DASHBOARD,
    icon: RedditIcon,
    children: NAVIGATION_REDDIT_NO_INTEGRATION_SIDEBAR_ITEMS,
  },
]
export const REDDIT_INTEGRATION_SIDEBAR_ITEMS: NavigationItemType[] = [
  {
    name: 'Reddit',
    href: ROUTE.REDDIT_DASHBOARD,
    icon: RedditIcon,
    children: NAVIGATION_REDDIT_SIDEBAR_ITEMS,
  },
]

const INTEGRATIONS_SIDEBAR_ITEM: NavigationItemType = {
  name: 'Integrations',
  href: '/integrations',
  icon: Link,
  useWarningHook: useHasIntegrationWarning,
}
export const YOUTUBE_INTEGRATION_SIDEBAR_ITEMS: NavigationItemType[] = [
  {
    name: 'YouTube',
    href: '/apps/youtube',
    icon: YoutubeIcon,
  },
  INTEGRATIONS_SIDEBAR_ITEM,
]

export const TASKS_SIDEBAR_ITEMS: NavigationItemType[] = [
  { name: 'Dashboard', href: '/dashboard', icon: Home },
  { name: 'Search', href: '/search', icon: Search },
  { name: 'Schedule', href: '/schedule' as Route, icon: Calendar },
  {
    name: 'Tasks',
    href: '/tasks' as Route,
    icon: CheckCircle,
  },
  INTEGRATIONS_SIDEBAR_ITEM,
]
export const NAVIGATION_SIDEBAR_ITEMS: NavigationItemType[] = [
  {
    name: 'Settings',
    basePath: '/settings',
    href: ROUTE.SETTINGS_PROFILE,
    icon: Settings,
    // useWarningHook: useNeedsSettingsWarning,
    children: NAVIGATION_SETTINGS_SIDEBAR_ITEMS,
  },
]
