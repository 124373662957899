'use client'

import { cn } from '@/web/libs/utils'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const labelVariants = cva(
  'text-sm font-medium peer-disabled:cursor-not-allowed [:disabled~&]:opacity-50 [:disabled~&]:cursor-not-allowed peer-disabled:opacity-70 [for]:cursor-pointer',
  {
    variants: {
      variant: {
        default: 'text-muted-foreground',
        secondary: 'text-secondary-foreground',
        primary: 'text-primary',
        error: 'text-destructive',
      },
      size: {
        lg: 'text-base',
        sm: 'text-xs',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, variant, size, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({ variant, size }), className)}
    {...props}
  />
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
