import {
  GMAIL_TITLE,
  GMAIL_TITLE_SIMPLE,
  REDDIT_TITLE,
  REDDIT_TITLE_SIMPLE,
  SLACK_TITLE,
  SLACK_TITLE_SIMPLE,
  TASKS_TITLE,
  TASKS_TITLE_SIMPLE,
  YOUTUBE_TITLE,
  YOUTUBE_TITLE_SIMPLE,
} from '@/shared/constants/company'
import { Icon } from '@/web/components/Icon'
import { GmailIcon } from '@/web/components/icons/GmailIcon'
import { RedditIcon } from '@/web/components/icons/RedditIcon'
import { SlackIcon } from '@/web/components/icons/SlackIcon'
import { YoutubeIcon } from '@/web/components/icons/YoutubeIcon'
import { Link } from '@/web/components/Link'
import { LogoStacked } from '@/web/components/LogoStacked'
import type { IconType } from '@/web/constants/icon'
import { NO_FOOTER_PATHS, PUBLIC_PATHS, ROUTE } from '@/web/constants/router'
import { hasRootPathMatch } from '@/web/utils/router'
import type { Route } from 'next'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

type FooterItemType = {
  name: string
  title?: string
  href: string
  Icon?: IconType
}

type FooterSectionType = [string, FooterItemType[]]
const DEFAULT_SECTIONS: FooterSectionType[] = [
  [
    'Company',
    [
      { name: 'Blog', href: '/blog' },
      { name: 'Support', href: '/support' },
      { name: 'Terms & Conditions', href: '/terms' },
      { name: 'Privacy Policy', href: '/privacy' },
      { name: 'GDPR Commitment', href: '/gdpr-commitment' },
      { name: 'Privacy Shield', href: '/privacy-shield' },
    ],
  ],
  [
    'Solutions',
    [
      {
        name: REDDIT_TITLE_SIMPLE,
        title: REDDIT_TITLE,
        href: ROUTE.REDDIT,
        Icon: RedditIcon,
      },
      {
        name: GMAIL_TITLE_SIMPLE,
        title: GMAIL_TITLE,
        href: ROUTE.GMAIL,
        Icon: GmailIcon,
      },
      {
        name: YOUTUBE_TITLE_SIMPLE,
        title: YOUTUBE_TITLE,
        href: ROUTE.YOUTUBE,
        Icon: YoutubeIcon,
      },
      {
        name: SLACK_TITLE_SIMPLE,
        title: SLACK_TITLE,
        href: ROUTE.SLACK,
        Icon: SlackIcon,
      },
      {
        name: TASKS_TITLE_SIMPLE,
        title: TASKS_TITLE,
        href: ROUTE.TASK_MANAGEMENT,
        Icon: Icon,
      },
    ],
  ],
]

// const ROUTE_BLOG_POSTS = {
//   [ROUTE.REDDIT]: REDDIT_BLOG_POSTS.map(post => ({
//     name: post.title,
//     href: post.href,
//   })),
// } as const satisfies Record<Route, FooterItemType[]>

export const Footer = () => {
  const router = useRouter()
  const session = useSession()
  const isHidingFooter =
    hasRootPathMatch(router.pathname, NO_FOOTER_PATHS) ||
    (!hasRootPathMatch(router.pathname, PUBLIC_PATHS) &&
      session.status !== 'unauthenticated')
  if (isHidingFooter) {
    return null
  }

  // const blogPostsKey = Object.keys(ROUTE_BLOG_POSTS).find(key =>
  //   router.pathname.startsWith(key)
  // )
  const sections = [...DEFAULT_SECTIONS]
  // if (blogPostsKey) {
  //   sections.push([
  //     'Blog',
  //     ROUTE_BLOG_POSTS[blogPostsKey as Route]!.slice(0, 5),
  //   ])
  // }

  return (
    <footer className='wavy-border-top bg-base-300'>
      <div className='mx-auto w-full xl:max-w-6xl 2xl:max-w-7xl overflow-hidden px-6 py-12 sm:py-16 lg:px-8'>
        <div className='flex flex-col-reverse items-center justify-center gap-16 xl:gap-32 sm:flex-row sm:items-start sm:justify-between'>
          <div className='w-[clamp(200px,25%,400px)] h-40'>
            <LogoStacked isDark className='max-h-full w-full' />
          </div>
          <section className='grid grid-cols-1 text-center md:text-left items-start justify-center md:justify-start gap-x-2 gap-y-8 md:gap-x-8 md:[grid-template-columns:repeat(2,minmax(150px,1fr))] xl:[grid-template-columns:repeat(3,minmax(150px,1fr))]'>
            {sections.map(([title, items], index) => (
              <div key={index} className='flex flex-col gap-2'>
                <h6>{title}</h6>
                <nav
                  className='flex flex-col flex-wrap items-center md:items-start gap-y-1 md:gap-y-2'
                  aria-label='Footer'>
                  {items.map(item => (
                    <div key={item.name} className='leading-[0]'>
                      <Link
                        key={item.name}
                        href={item.href as Route}
                        title={item.title ?? item.name}
                        size='sm'
                        variant='underline'
                        theme='secondary'
                        className='group'>
                        {'Icon' in item && item.Icon ? (
                          <item.Icon className='size-3 grayscale group-hover:grayscale-0 inline align-baseline mr-2' />
                        ) : null}
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </nav>
              </div>
            ))}
          </section>
        </div>
      </div>
    </footer>
  )
}
