import { type GmailEmailType } from '@/shared/constants/gmail'
import { areEmailsEqual } from '@/shared/utils/string'
import _ from 'lodash'

//////////
// BODY //
//////////

// Regular expression to match the patterns used by different email clients
// for quoting previous emails in a thread.
const quotePatterns = [
  /^>/gm, // Pattern for lines starting with ">"
  /On [\s\S]*wrote:/gm, // Pattern for "On X, Y wrote:" lines
  /Sent from my iPhone/,
  /-----Original Message-----/gm,
]
export function isolateLatestEmailPlainText(emailContent: string): string {
  let isolatedContent = emailContent

  // Loop through the quote patterns and split the content
  for (const pattern of quotePatterns) {
    const matches = emailContent.match(pattern)
    if (matches) {
      const firstMatchIndex = emailContent.indexOf(matches[0])
      if (firstMatchIndex >= 0) {
        isolatedContent = isolatedContent.substring(0, firstMatchIndex)
      }
    }
  }

  isolatedContent = isolatedContent.trim()

  if (isolatedContent !== emailContent) {
    console.log('Isolated latest email:', isolatedContent)
  }

  return isolatedContent.trim() // Trim whitespace from the isolated content
}

export function splitEmailContent(html: string): {
  latestMessageHtml: string
  previousMessagesHtml: string
} {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  // Find the rest of the thread
  const firstQuote =
    doc.querySelector('.gmail_quote') ?? doc.querySelector('blockquote')

  let latestMessageHtml = html
  let previousMessagesHtml = ''

  // Make the latest message the html without the rest of the thread
  if (firstQuote) {
    firstQuote.remove()
    latestMessageHtml = doc.body.innerHTML
    previousMessagesHtml = firstQuote.outerHTML
  }

  console.log('Split email content', {
    latestMessageHtml,
    previousMessagesHtml,
  })

  return { latestMessageHtml, previousMessagesHtml }
}

///////////////////
// EMAIL ADDRESS //
///////////////////

export function getMatchingEmailAddressInEmailStrings({
  emailAddresses,
  emailStrings,
}: {
  emailAddresses?: string[]
  emailStrings?: string[]
}): string | null {
  if (!emailAddresses || emailAddresses.length === 0) {
    console.log(
      'No email addresses provided to match to email strings %o',
      emailStrings
    )
    return null
  }

  // Check if any of the current user's email addresses are in the 'To' field of the email
  const currentUserEmailInToField =
    emailAddresses.find(currentUserEmail => {
      return emailStrings?.some(emailString => {
        const { email } = parseEmailString(emailString)
        if (!email) {
          console.log(
            'Skipping "%s" because it does not contain an email address',
            emailString
          )
          return false
        }

        return areEmailsEqual(email, currentUserEmail)
      })
    }) ?? null
  console.log(
    '%s email address matching email strings: %o %O',
    currentUserEmailInToField ? 'Found' : 'Did not find',
    currentUserEmailInToField,
    {
      currentUserEmails: emailAddresses,
      emailStrings,
    }
  )

  return currentUserEmailInToField ?? null
}

export function isEmailFromOneOfEmailAddresses({
  email,
  emailAddresses,
}: {
  email: GmailEmailType
  emailAddresses: string[]
}): boolean {
  return (
    getMatchingEmailAddressInEmailStrings({
      emailStrings: [email.from],
      emailAddresses,
    }) != null
  )
}

export function getMatchingEmailAddressInEmailRecipientFields({
  email,
  emailAddresses,
}: {
  email?: GmailEmailType
  emailAddresses?: string[]
}): string | null {
  if (!email) {
    return null
  }
  const toCcBcc = [...email.to, ...(email.cc ?? []), ...(email.bcc ?? [])]

  return getMatchingEmailAddressInEmailStrings({
    emailStrings: toCcBcc,
    emailAddresses,
  })
}

export function getReplyAllRecipientFields({
  email,
  from,
}: {
  email: GmailEmailType
  from: string
}): {
  to: string[]
  cc: string[] | undefined
} {
  console.log(
    'Get reply all recipient fields for email %o from %o',
    email.subject,
    from
  )
  const { email: fromEmailAddress } = parseEmailString(from)
  const currentEmailString = fromEmailAddress
    ? getMatchingEmailAddressInEmailRecipientFields({
        email,
        emailAddresses: [fromEmailAddress],
      }) ?? from
    : from

  const to = withoutEmailString(
    [email.from, ...email.to],
    currentEmailString
  ).filter(Boolean)
  const cc = email.cc?.length
    ? withoutEmailString(email.cc, currentEmailString)
    : undefined

  console.log(
    'Found reply all recipient fields for email %o from %o: %O',
    email.subject,
    from,
    { to, cc }
  )
  return { to, cc }
}

export function createEmailString({
  name,
  email,
}: {
  name?: string | null
  email?: string | null
}): string | null {
  // Check if the name is provided and not just empty spaces
  if (name && name.trim() !== '' && email) {
    return `${name.trim()} <${email}>`
  }
  if (email) {
    return email
  }
  return null
}

export function parseEmailString(emailString?: string | null): {
  name: string | null
  email: string | null
} {
  if (!emailString) {
    return { name: null, email: null }
  }

  const emailRegex = /(?:^|\s)([^<\s]+@[^>\s]+)(?:\s|$)/
  const nameAndEmailRegex = /"?\s*(.*?)\s*"?<([^>]+)>/

  let email = null
  let name = null

  // Check if the string is in the format "Name <email>"
  const nameAndEmailMatch = emailString.match(nameAndEmailRegex)
  if (nameAndEmailMatch) {
    name = nameAndEmailMatch[1]?.trim() ?? null
    email = nameAndEmailMatch[2]?.trim() ?? null
  } else {
    // If not, it might be just an email
    const emailMatch = emailString.match(emailRegex)
    email = emailMatch ? emailMatch[1]?.trim() ?? null : null
    name = null // No name provided
  }

  console.assert(
    !!email,
    `Email not found in email string "${emailString}": ${email}`
  )

  return { name, email }
}

export function formatEmailHeaderString(emailString: string): string | null {
  const { name, email } = parseEmailString(emailString)
  if (!email) {
    return null
  }
  if (!name) {
    return email
  }
  return `"${name}" <${email}>`
}

export function formatEmailsHeaderString(emailStrings: string[]): string {
  return emailStrings.map(formatEmailHeaderString).filter(Boolean).join(', ')
}

export function isMatchingEmailString(
  emailStringA?: string | null,
  emailStringB?: string | null
): boolean {
  if (!emailStringA || !emailStringB) {
    return false
  }

  const { email: emailA } = parseEmailString(emailStringA)
  const { email: emailB } = parseEmailString(emailStringB)
  if (!emailA || !emailB) {
    return false
  }
  return areEmailsEqual(emailA, emailB)
}

function withoutEmailString(emailStrings: string[], emailString: string) {
  return emailStrings.filter(
    email => !isMatchingEmailString(email, emailString)
  )
}

export function getUniqueEmailStrings(emailStrings: string[]): string[] {
  return _.uniqWith(emailStrings, isMatchingEmailString)
}

//////////////
// SANITIZE //
//////////////

const trailingNewlineRegex = /^[^\w]*$/
function removeTrailingNonAlphanumericLines(body: string): string {
  const lines = body.split('\n')
  while (
    lines.length > 0 &&
    trailingNewlineRegex.test(lines[lines.length - 1]!.trim())
  ) {
    lines.pop()
  }
  return lines.join('\n')
}

const signatureRegex = /--\s*\n[\s\S]*$/g
export function sanitizeEmailBody(
  body: string,
  { isRemovingSignature = true }: { isRemovingSignature: boolean } = {
    isRemovingSignature: true,
  }
): string {
  let sanitizedBody = body.trim()

  if (isRemovingSignature) {
    console.log('Removing signature from email body %s', body)
    sanitizedBody = sanitizedBody.replace(signatureRegex, '')
  }

  // Remove any trailing lines that only have non-alphanumeric characters
  console.log(
    'Removing trailing newlines from email body %s',
    body.substring(0, 100)
  )
  sanitizedBody = removeTrailingNonAlphanumericLines(sanitizedBody)

  // Condense >> to >
  console.log('Condensing >> to > in email body %s', body.substring(0, 100))
  sanitizedBody = sanitizedBody.replace(/>+/g, '>')

  console.log('Sanitized email body: %s', sanitizedBody.substring(0, 100))
  return sanitizedBody
}
