import {
  PricingTierId,
  TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT,
  type PricingActivityUsageType,
} from '@/shared/constants/pricing'
import { usePricingTierId } from '@/web/hooks/useCurrentPricingTierId'
import { api } from '@/web/utils/api'
import type { ActivityType } from '@prisma/client'

export const useMaxActivityUsage = (
  activityTypes: readonly ActivityType[]
): PricingActivityUsageType | null => {
  const pricingTierId = usePricingTierId()

  const usageCountQueryResult = api.usage.count.useQuery()
  const usageCount = usageCountQueryResult.data

  let maxUsage: PricingActivityUsageType | null = null

  for (const activityType of activityTypes) {
    const limit =
      TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT[pricingTierId ?? PricingTierId.FREE][
        activityType
      ]
    const limitCount = limit != null && 'count' in limit ? limit.count : null

    const monthUsageCount =
      usageCount?.byMonth.find(
        activityLogCount => activityLogCount.activityType === activityType
      )?.count ?? 0
    const progress = limitCount ? monthUsageCount / limitCount : 0
    if (!progress) continue

    if (
      !maxUsage ||
      !maxUsage.max ||
      progress > maxUsage.count / maxUsage.max
    ) {
      maxUsage = {
        activityType,
        max: limitCount,
        count: monthUsageCount,
        progress,
      }
    }
  }

  return maxUsage
}
