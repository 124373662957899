import { COMPANY_NAME } from '@/shared/constants/company'
import { LogoVertical } from '@/web/components/LogoVertical'
import { useRootRoute } from '@/web/hooks/useRootRoute'
import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import Link from 'next/link'
import type { ComponentProps } from 'react'

/** Screenshot CSS
 *  --------------
    line-height: 500px;
    font-size: 500px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    position: fixed;
 */

const variants = cva(
  'text-gradient flex flex-col items-center overflow-visible text-center font-semibold tracking-tight transition-all hover:animate-pulse',
  {
    variants: {
      size: {
        sm: 'h-10 2xl:h-12',
        default: 'lg:h-18 h-14',
        lg: 'h-32',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
)
export const Logo = ({
  size,
  href,
  className,
  ...linkProps
}: VariantProps<typeof variants> & { href?: string } & Partial<
    ComponentProps<typeof Link>
  >) => {
  const rootRoute = useRootRoute()

  return (
    <Link
      title={COMPANY_NAME}
      {...linkProps}
      href={href ?? rootRoute}
      className={cn(variants({ size }), className)}>
      <LogoVertical className='h-full' />
    </Link>
  )
}
