import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useActiveLink = (targetIds: readonly string[]) => {
  const [activeLink, setActiveLink] = useState<string | null>(null)
  // Update whenever pathname changes to handle navigating back from blog post
  const router = useRouter()

  useEffect(() => {
    const observers: IntersectionObserver[] = []

    targetIds.forEach(id => {
      const targetElement = document.getElementById(id)
      if (targetElement) {
        const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              const isTopVisible =
                entry.boundingClientRect.top >= 0 &&
                entry.boundingClientRect.top <= window.innerHeight
              const isBottomVisible =
                entry.boundingClientRect.bottom >= 0 &&
                entry.boundingClientRect.bottom <= window.innerHeight
              if (isTopVisible || isBottomVisible) {
                setActiveLink(id)
              }
            })
          },
          {
            threshold: 0, // Minimal threshold
            rootMargin: '0px',
          }
        ) // Adjust the threshold as needed

        observer.observe(targetElement)
        observers.push(observer)
      }
    })

    return () => {
      observers.forEach(observer => observer.disconnect())
    }
  }, [targetIds, router.pathname])

  // Update when asPath changes
  useEffect(() => {
    // If it ends in any of the targetIds, it's active
    const activeId = targetIds.find(id => router.asPath.endsWith(`#${id}`))
    if (activeId) {
      setActiveLink(activeId)
    } else {
      setActiveLink(null)
    }
  }, [router.asPath])

  return activeLink
}
