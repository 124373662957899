import { condenseNumber } from '@/shared/utils/number'
import { getProgressTheme } from '@/shared/utils/theme'
import { cn } from '@/web/libs/utils'

export const TextCounter = ({
  count,
  max,
  className,
  isLoading,
  isCondensed,
}: {
  count?: number
  max?: number
  className?: string
  isLoading?: boolean
  isCondensed?: boolean
}) => {
  if (!max) {
    return null
  }

  const theme = getProgressTheme(
    count != null && max !== Number.POSITIVE_INFINITY
      ? (count ?? 0) / max
      : null
  )
  return (
    <span
      className={cn(
        className,
        theme === 'success' && 'text-success',
        theme === 'error' && 'text-destructive',
        theme === 'primary' && 'text-primary',
        theme === 'warning' && 'text-warning'
      )}>
      {isLoading
        ? '-'
        : isCondensed
        ? condenseNumber(count, {
            maxChars: 4,
          })
        : count}{' '}
      /{' '}
      {max === Number.POSITIVE_INFINITY
        ? '∞'
        : isCondensed
        ? condenseNumber(max, { maxChars: 4 })
        : max}
    </span>
  )
}
