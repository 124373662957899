export function condenseNumber(
  num?: number,
  {
    defaultRemainingChars = 0,
    maxChars,
  }: { defaultRemainingChars?: number; maxChars?: number } = {}
): string {
  if (!num) {
    return '0'
  }

  if (maxChars && num.toString().length <= maxChars) {
    return num.toString()
  }

  let suffix = ''
  if (num >= 1000000) {
    num = num / 1000000
    suffix = 'M'
  } else if (num >= 1000) {
    num = num / 1000
    suffix = 'K'
  }

  const minChars = num.toFixed(0).length + suffix.length
  const remainingChars = maxChars
    ? Math.max(maxChars - minChars, 0)
    : defaultRemainingChars

  let formattedNumber = num.toFixed(remainingChars > 1 ? remainingChars - 1 : 0)

  formattedNumber = formattedNumber.replace(/\.$/, '').replace(/\.0+$/, '')

  return formattedNumber + suffix
}
