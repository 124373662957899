import { Button } from '@/web/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/web/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import { ScrollArea } from '@/web/components/ui/scroll-area'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { cn } from '@/web/libs/utils'
import { Check, ChevronsUpDown, Plus } from 'lucide-react'
import * as React from 'react'
import { type ComponentProps, type ReactNode } from 'react'
import { toast } from 'sonner'

type ValueType = string | undefined

interface Option<T extends ValueType> {
  value: T
  label: string
  labelNode?: ReactNode
}

interface SelectProps<T extends ValueType> {
  className?: string
  options: Option<T>[]
  value: T
  onChange: (newValue: T) => void
  placeholder?: string
  placeholderNoun?: string
  size?: React.ComponentProps<typeof Button>['size']
  buttonProps?: React.ComponentProps<typeof Button>
  renderValue?: (value: T) => ReactNode
  isSearchable?: boolean
  customButton?: ReactNode
  onOpenChange?: (open: boolean) => void
  getEmptySearchOption?: (query: string) => Option<T>
  onCreateOption?: (query: string) => string | void
  canDeselect?: T extends undefined ? true : false | undefined
  tooltip?: ReactNode
  theme?: ComponentProps<typeof Button>['theme']
}

function SelectBase<T extends ValueType>(
  {
    options,
    value,
    onChange,
    onOpenChange,
    size,
    placeholder,
    placeholderNoun,
    buttonProps,
    isSearchable = true,
    renderValue,
    customButton,
    className,
    getEmptySearchOption,
    onCreateOption,
    canDeselect,
    tooltip,
    theme = 'secondary',
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [open, setOpen] = React.useState(false)
  const [query, setQuery] = React.useState('')

  React.useEffect(() => {
    onOpenChange?.(open)
  }, [open, onOpenChange])

  const emptySearchOption =
    getEmptySearchOption && query ? getEmptySearchOption(query) : null
  const availableOptions = [
    ...options,
    ...(emptySearchOption ? [emptySearchOption] : []),
  ]

  const selectedOption = options.find(opt => opt.value === value)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            asChild
            onClick={e => {
              e.preventDefault()
              setOpen(!open)
            }}>
            <PopoverTrigger
              asChild
              onClick={e => {
                e.stopPropagation()
              }}>
              {customButton ?? (
                <Button
                  variant='outline'
                  theme={theme}
                  role='combobox'
                  aria-expanded={open}
                  size={size}
                  {...buttonProps}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  className={cn(
                    'w-full justify-between',
                    buttonProps?.className
                  )}
                  ref={ref}>
                  {renderValue
                    ? renderValue(value)
                    : selectedOption?.labelNode ??
                      selectedOption?.label ??
                      placeholder}
                  <ChevronsUpDown className='h-4 w-4 shrink-0 opacity-50' />
                </Button>
              )}
            </PopoverTrigger>
          </TooltipTrigger>
          {tooltip ? <TooltipContent>{tooltip}</TooltipContent> : null}
        </Tooltip>
      </TooltipProvider>
      <PopoverContent className={cn('p-0', className)}>
        <Command>
          {isSearchable ? (
            <CommandInput
              placeholder={`Search ${
                placeholderNoun ?? placeholder ?? 'items'
              }...`}
              onValueChange={setQuery}
              autoFocus
            />
          ) : null}
          <ScrollArea className='max-h-64 overflow-auto'>
            {isSearchable ? (
              <CommandEmpty className='py-2'>
                {query ? (
                  <>No {placeholderNoun ?? placeholder ?? 'items'} found.</>
                ) : (
                  getEmptySearchOption?.(query)?.labelNode ??
                  getEmptySearchOption?.(query)?.label
                )}
              </CommandEmpty>
            ) : null}
            {availableOptions.length ? (
              <CommandGroup>
                <CommandList>
                  {availableOptions.map(option => (
                    <CommandItem
                      key={option.value}
                      keywords={[option.label]}
                      onSelect={() => {
                        if (option === emptySearchOption && onCreateOption) {
                          const createResult = onCreateOption(query)
                          if (createResult) {
                            toast.error(createResult)
                            return
                          }

                          setQuery('')
                          return
                        } else if (canDeselect && option.value === value) {
                          onChange(undefined as T)
                        } else {
                          onChange(option.value as T)
                        }

                        setOpen(false)
                      }}
                      value={option.value}>
                      {option === emptySearchOption && onCreateOption ? (
                        <Plus className='mr-2 h-4 w-4' />
                      ) : (
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            value === option.value ? 'opacity-100' : 'opacity-0'
                          )}
                        />
                      )}
                      {option.labelNode ?? option.label}
                    </CommandItem>
                  ))}
                </CommandList>
              </CommandGroup>
            ) : null}
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const Select = React.forwardRef(SelectBase)

Select.displayName = 'Select'
