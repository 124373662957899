'use client'

import { Logo } from '@/web/components/Logo'
import { Button } from '@/web/components/ui/button'
import { Sheet, SheetContent } from '@/web/components/ui/sheet'
import {
  NAVBAR_ACTIVE_SECTION_IDS,
  ROUTE_NAVBAR_ITEMS,
} from '@/web/constants/navigation'
import { ROUTE } from '@/web/constants/router'
import { useActiveLink } from '@/web/hooks/use-active-link'
import { useRootRoute } from '@/web/hooks/useRootRoute'
import { usePrevious } from '@dnd-kit/utilities'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { signIn, useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const ScrollLink = dynamic(
  () => import('@/web/components/ScrollLink').then(mod => mod.ScrollLink),
  {
    ssr: false,
  }
)

export const LandingNavbar = () => {
  const session = useSession()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const router = useRouter()
  const activeLink = useActiveLink(NAVBAR_ACTIVE_SECTION_IDS)

  const rootUnauthenticatedRoute = useRootRoute({
    type: 'unauthenticated',
  })
  const rootAuthenticatedRoute = useRootRoute({
    type: 'authenticated',
  })
  // const rootRoute = useRootRoute()

  // Close if path changes
  const previousPath = usePrevious(router.asPath)
  useEffect(() => {
    if (mobileMenuOpen && router.asPath !== previousPath) {
      setMobileMenuOpen(false)
    }
  }, [router.asPath, previousPath, mobileMenuOpen, setMobileMenuOpen])

  return (
    <>
      <nav
        className='sticky top-0 lg:fixed xl:top-3 xl:rounded-full z-50 bg-white/50 shadow-md backdrop-blur-md w-full py-2 px-4 xl:max-w-6xl 2xl:max-w-7xl lg:-translate-x-1/2 lg:left-1/2 flex items-center justify-between text-center sm:text-left'
        aria-label='Global'>
        <Logo size='sm' href={rootUnauthenticatedRoute} />
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400'
            onClick={() => setMobileMenuOpen(true)}>
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='hidden lg:flex lg:flex-1 lg:justify-end items-center gap-4 xl:gap-8'>
          {ROUTE_NAVBAR_ITEMS[rootUnauthenticatedRoute]?.map(item => (
            <ScrollLink
              key={item.name}
              href={
                router.pathname !== rootUnauthenticatedRoute
                  ? rootUnauthenticatedRoute
                  : undefined
              }
              name={item.name}
              id={item.id}
              isActive={activeLink === item.id}
            />
          ))}
          <Link
            href={
              session.status === 'authenticated'
                ? rootAuthenticatedRoute
                : ROUTE.SIGN_IN
            }>
            <Button variant='outline' theme='primary' size='sm'>
              {session.status === 'authenticated'
                ? 'Dashboard'
                : 'Get Started for Free'}
            </Button>
          </Link>
        </div>
      </nav>
      <Sheet open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
        <SheetContent side='right'>
          <div className='flex items-center justify-start'>
            <Logo
              href={rootUnauthenticatedRoute}
              onClick={() => setMobileMenuOpen(false)}
            />
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6 empty:hidden'>
                {ROUTE_NAVBAR_ITEMS[rootUnauthenticatedRoute]?.map(item => (
                  <ScrollLink
                    isActive={activeLink === item.id}
                    href={
                      router.pathname !== rootUnauthenticatedRoute
                        ? rootUnauthenticatedRoute
                        : undefined
                    }
                    key={item.name}
                    name={item.name}
                    id={item.id}
                    onClick={() => setMobileMenuOpen(false)}
                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  />
                ))}
              </div>
              <div className='py-6'>
                {session.status === 'authenticated' ? (
                  <Link
                    href={rootAuthenticatedRoute}
                    onClick={() => setMobileMenuOpen(false)}>
                    <Button size='sm' theme='premium'>
                      Dashboard
                    </Button>
                  </Link>
                ) : (
                  <>
                    <Button
                      size='sm'
                      theme='premium'
                      onClick={() => {
                        setMobileMenuOpen(false)
                        signIn()
                      }}>
                      Get Started for Free
                    </Button>
                    <Button
                      size='sm'
                      variant='ghost'
                      theme='secondary'
                      onClick={() => {
                        setMobileMenuOpen(false)
                        signIn()
                      }}>
                      Sign In
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  )
}
