import { type ScopeId } from '@/shared/constants/pusher'
import { LooseUrlSchema } from '@/shared/constants/zod'
import { z } from 'zod'

// @see https://platform.openai.com/docs/models
export enum LLM_MODEL_NAME {
  'gpt-3.5-turbo' = 'gpt-3.5-turbo',
  'gpt-4o' = 'gpt-4o-2024-08-06',
  'gpt-4o-mini' = 'gpt-4o-mini',
}
export type LlmModelType = (typeof LLM_MODEL_NAME)[keyof typeof LLM_MODEL_NAME]
export type LlmBaseModelType = 'gpt-3.5' | 'gpt-4o' | 'gpt-4o-mini'
export const DEFAULT_MODEL_NAME: LlmModelType = LLM_MODEL_NAME['gpt-4o-mini'] //'gpt-4o-mini'
export const BASE_MODEL: Record<LlmModelType, LlmBaseModelType> = {
  'gpt-3.5-turbo': 'gpt-3.5',
  'gpt-4o-mini': 'gpt-4o-mini',
  [LLM_MODEL_NAME['gpt-4o']]: 'gpt-4o',
}
export const MODEL_INPUT_1M_TOKENS_PRICE: Record<LlmBaseModelType, number> = {
  'gpt-3.5': 0.5,
  'gpt-4o-mini': 0.15,
  'gpt-4o': 2.5,
}
export const MODEL_OUTPUT_1M_TOKENS_PRICE: Record<LlmBaseModelType, number> = {
  'gpt-3.5': 1.5,
  'gpt-4o-mini': 0.6,
  'gpt-4o': 10,
}
export const MODEL_CONTEXT_SIZE: Record<LlmBaseModelType, number> = {
  'gpt-3.5': 16000,
  'gpt-4o-mini': 128000,
  'gpt-4o': 128000,
}

// @see https://platform.openai.com/docs/models/gpt-4o-mini
// TODO: Make this a mapping for each model
export const MAX_OUTPUT_TOKENS = 16384
// How much tokens can be expected to vary between chunks of text
export const CHUNK_TOKEN_VARIANCE_THRESHOLD = 0.8

export const LinkSchema = z.object({
  url: LooseUrlSchema,
  title: z.string(),
})
export type LinkType = z.infer<typeof LinkSchema>
export const DateSchema = z.object({
  date: z.string().datetime().or(z.date()),
  title: z.string(),
})
export type DateType = z.infer<typeof DateSchema>
export const ContactSchema = z.object({
  name: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  address: z.string().optional(),
  photoUrl: z.string().url().optional(),
})
export type ContactType = z.infer<typeof ContactSchema>

export const SummaryMetadataSchema = z.record(z.string())
export type SummaryMetadataType = z.infer<typeof SummaryMetadataSchema>

export const SummaryKeyPointsSchema = z.array(z.string())
export const SummaryActionItemsSchema = z.array(z.string())

export const SummarySchema = z.object({
  summary: z.string(),
  links: z.array(LinkSchema).optional(),
  dates: z.array(DateSchema).optional(),
  contacts: z.array(ContactSchema).optional(),
  metadata: SummaryMetadataSchema.optional(),
  keyPoints: SummaryKeyPointsSchema.optional(),
  actionItems: SummaryActionItemsSchema.optional(),
})
export type SummaryType = z.infer<typeof SummarySchema>

export type MetadataType = {
  scopeId?: ScopeId
  userId?: string
  taskId?: string
  taskTitle?: string
  integrationName?: string
  type?: 'agent' | 'summary' | 'prompt'
}

export type TokenCountMetadataType = {
  tokenCount: number
}
