import { TASKS_INTEGRATIONS } from '@/shared/constants/integration'
import {
  NAVIGATION_SIDEBAR_ITEMS,
  REDDIT_INTEGRATION_SIDEBAR_ITEMS,
  REDDIT_NO_INTEGRATION_SIDEBAR_ITEMS,
  TASKS_SIDEBAR_ITEMS,
  YOUTUBE_INTEGRATION_SIDEBAR_ITEMS,
} from '@/web/constants/navigation'
import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'
import { usePrimaryIntegration as usePrimaryIntegrationName } from '@/web/hooks/usePrimaryIntegration'
import { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

export const useSidebarItems = () => {
  const [sidebarItems, setSidebarItems] = useState(NAVIGATION_SIDEBAR_ITEMS)

  const integrationListQueryResult = useIntegrationListQueryResult()

  const primaryIntegrationName = usePrimaryIntegrationName()

  const hasRedditIntegration = integrationListQueryResult.data?.some(
    integration =>
      integration.name === IntegrationName.REDDIT && integration.isEnabled
  )
  const hasYoutubeIntegration = integrationListQueryResult.data?.some(
    integration =>
      integration.name === IntegrationName.YOUTUBE && integration.isEnabled
  )
  const hasTasksIntegration = integrationListQueryResult.data?.some(
    integration =>
      TASKS_INTEGRATIONS.includes(integration.name) && integration.isEnabled
  )
  useEffect(() => {
    if (hasTasksIntegration) {
      setSidebarItems(items =>
        _.uniqBy([...TASKS_SIDEBAR_ITEMS, ...items], 'href')
      )
    }

    if (hasYoutubeIntegration) {
      setSidebarItems(items =>
        _.uniqBy([...YOUTUBE_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
      )
    }

    if (hasRedditIntegration) {
      setSidebarItems(items =>
        _.uniqBy([...REDDIT_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
      )
    } else if (primaryIntegrationName === IntegrationName.REDDIT) {
      setSidebarItems(items =>
        _.uniqBy([...REDDIT_NO_INTEGRATION_SIDEBAR_ITEMS, ...items], 'href')
      )
    }
  }, [
    hasRedditIntegration,
    hasYoutubeIntegration,
    hasTasksIntegration,
    primaryIntegrationName,
  ])

  return sidebarItems
}
