'use client'

import { COMPANY_NAME } from '@/shared/constants/company'
import {
  DEFAULT_PAGE_HEAD_DATA,
  ROUTE_PAGE_HEAD_DATA,
} from '@/web/constants/router'
import { usePageTitle } from '@/web/hooks/usePageTitle'
import { hasRootPathMatch } from '@/web/utils/router'
import Head from 'next/head'
import { useRouter } from 'next/router'

export const PageHead = () => {
  const pageTitle = usePageTitle()

  const router = useRouter()
  const pageHeadDataKey = Object.keys(ROUTE_PAGE_HEAD_DATA).find(route =>
    hasRootPathMatch(router.pathname, [route])
  )
  const { title, description, url } = pageHeadDataKey
    ? ROUTE_PAGE_HEAD_DATA[pageHeadDataKey]!
    : DEFAULT_PAGE_HEAD_DATA

  return (
    <Head>
      <title>{`${pageTitle ? `${pageTitle} | ` : ''}${title}`}</title>
      <meta key='description' name='description' content={description} />
      <meta key='og:type' property='og:type' content='website' />
      <meta key='og:site_name' property='og:site_name' content={COMPANY_NAME} />
      <meta key='og:title' property='og:title' content={title} />
      <meta
        key='og:description'
        property='og:description'
        content={description}
      />
      <meta key='og:image' property='og:image' content='/logo/Vertical.png' />
      <meta key='og:url' property='og:url' content={url} />
      <meta
        key='twitter:card'
        name='twitter:card'
        content='summary_large_image'
      />
      <meta key='twitter:title' name='twitter:title' content={title} />
      <meta
        key='twitter:description'
        name='twitter:description'
        content={description}
      />
      <meta
        key='twitter:image'
        name='twitter:image'
        content='/logo/Vertical.png'
      />
      <meta key='twitter:url' name='twitter:url' content={url} />
    </Head>
  )
}
