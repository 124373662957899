import { getTierId } from '@/shared/utils/subscription'
import { api } from '@/web/utils/api'
import { useSession } from 'next-auth/react'

export const usePricingTierId = () => {
  const subscriptionInfoQueryResult = api.subscription.info.useQuery()
  const subscription = subscriptionInfoQueryResult.data
  const session = useSession()
  const currentTierId = getTierId({
    subscription,
    role: session.data?.user?.role,
  })

  return currentTierId
}
