import { PRIMARY, SECONDARY, TERTIARY } from '@/shared/constants/color'
import { type SVGProps } from 'react'

export const Icon = ({
  isDark,
  ...svgProps
}: { isDark?: boolean } & Partial<SVGProps<SVGSVGElement>>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 130.14 135.99'
      {...svgProps}>
      <path
        fill={PRIMARY}
        d='m79,21.02s0,0,0,0c-.7,0-1.4.02-2.1.06-5.06-.1-9.66-2.05-13.16-5.21-.33-.3-.65-.61-.96-.93-4.19-3.8-9.75-6.12-15.85-6.12-13.02,0-23.58,10.56-23.58,23.58,0,6.33,2.5,12.08,6.57,16.32.81.84,1.68,1.63,2.6,2.34,3.41,3.63,5.5,8.52,5.5,13.9s-2.04,10.15-5.39,13.77c-.41.44-.83.87-1.28,1.27-4.68,4.96-7.56,11.65-7.56,19.01,0,15.3,12.41,27.71,27.71,27.71,12.44,0,22.96-8.19,26.46-19.48.19-.62.36-1.26.51-1.91,1.84-5.74,6.16-10.36,11.7-12.61.63-.2,1.25-.42,1.86-.65,13.84-5.26,23.69-18.65,23.69-34.34,0-20.28-16.44-36.73-36.73-36.73Z'
      />
      <path
        fill={TERTIARY}
        d='m56.35,110.9c9.1,0,16.79-5.99,19.36-14.25.14-.46.26-.92.37-1.39,1.35-4.2,4.51-7.58,8.56-9.22.46-.15.91-.31,1.36-.48,10.13-3.85,17.33-13.64,17.33-25.12,0-14.84-12.03-26.87-26.87-26.87h0c-.52,0-1.03.02-1.53.05-3.7-.08-7.07-1.5-9.63-3.81-.24-.22-.48-.45-.7-.68-3.06-2.78-7.13-4.47-11.59-4.47-9.53,0-17.25,7.72-17.25,17.25,0,4.63,1.83,8.84,4.8,11.94.59.62,1.23,1.19,1.91,1.71,2.49,2.66,4.03,6.23,4.03,10.17s-1.5,7.43-3.94,10.08c-.3.32-.61.63-.94.93-3.43,3.63-5.53,8.52-5.53,13.91,0,11.19,9.07,20.27,20.27,20.27Z'
      />
      <path
        fill={SECONDARY}
        d='m73.92,46.13s0,0,0,0c-.33,0-.65.01-.97.03-2.34-.05-4.47-.95-6.1-2.41-.15-.14-.3-.28-.45-.43-1.94-1.76-4.51-2.83-7.34-2.83-6.03,0-10.92,4.89-10.92,10.92,0,2.93,1.16,5.59,3.04,7.56.37.39.78.75,1.21,1.08,1.58,1.68,2.55,3.94,2.55,6.43s-.95,4.7-2.49,6.38c-.19.2-.39.4-.59.59-2.17,2.3-3.5,5.39-3.5,8.8,0,7.09,5.74,12.83,12.83,12.83,5.76,0,10.63-3.79,12.25-9.02.09-.29.17-.58.24-.88.85-2.66,2.85-4.8,5.42-5.84.29-.09.58-.19.86-.3,6.41-2.44,10.97-8.63,10.97-15.9,0-9.39-7.61-17-17-17.01Z'
      />
    </svg>
  )
}
