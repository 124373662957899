import {
  FREE_TRIAL_DAYS,
  PRICING_TIER_IDS,
  PricingTierId,
  TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT,
  type FeatureType,
  type PricingPeriod,
  type PricingTierLimitType,
} from '@/shared/constants/pricing'
import dayjs from '@/shared/singletons/dayjs'
import { parsePriceLookupKey } from '@/shared/utils/pricing'
import { Role, type ActivityType } from '@prisma/client'
import type Stripe from 'stripe'

export function getFreeTrialDaysRemaining({
  signupDate,
}: {
  signupDate?: Date
}): number {
  if (!signupDate) {
    return 0
  }
  return FREE_TRIAL_DAYS - dayjs().diff(signupDate, 'day')
}

export function isFreeTrial({
  subscription,
  signupDate,
}: {
  subscription?: Stripe.Subscription | null
  signupDate?: Date
}): boolean {
  return (
    subscription?.status === 'trialing' ||
    getFreeTrialDaysRemaining({ signupDate }) > 0
  )
}

export function isUpgrading(
  currentTierId: PricingTierId | null,
  newTierId: PricingTierId
) {
  const currentTierIndex = PRICING_TIER_IDS.indexOf(
    currentTierId ?? PricingTierId.FREE
  )
  const newTierIndex = PRICING_TIER_IDS.indexOf(newTierId)

  return newTierIndex > currentTierIndex
}

export function parseSubscriptionPriceData(
  subscription?: Stripe.Subscription | null
): {
  priceId: string | null
  pricingTierId: PricingTierId | null
  pricingPeriod: PricingPeriod | null
} {
  if (!subscription) {
    return {
      priceId: null,
      pricingTierId: null,
      pricingPeriod: null,
    }
  }

  const isSubscriptionActive = subscription?.status === 'active'
  const price = subscription.items.data?.[0]?.price
  const subscriptionPricingData =
    isSubscriptionActive && price ? parsePriceLookupKey(price.lookup_key) : null
  // @deprecated switch to new subscription handling to support up/downgrade + dynamic pricing
  const oldSubscriptionPricingTierId = isSubscriptionActive
    ? subscription?.metadata?.pricingTierId
    : null
  const subscriptionPricingTierIdString =
    subscriptionPricingData?.pricingTierId ??
    oldSubscriptionPricingTierId ??
    null

  const subscriptionPricingTierId =
    typeof subscriptionPricingTierIdString === 'string' &&
    PRICING_TIER_IDS.includes(subscriptionPricingTierIdString as PricingTierId)
      ? (subscriptionPricingTierIdString as PricingTierId)
      : null

  return {
    priceId: price?.id ?? null,
    pricingTierId: subscriptionPricingTierId,
    pricingPeriod: subscriptionPricingData?.pricingPeriod ?? null,
  }
}

export function getTierId({
  subscription,
  role,
}: {
  subscription?: Stripe.Subscription | null
  role?: Role | null
}): PricingTierId | null {
  const isSubscriptionActive = subscription?.status === 'active'
  const subscriptionPricingData = isSubscriptionActive
    ? parsePriceLookupKey(subscription.items.data?.[0]?.price!.lookup_key)
    : null
  const oldSubscriptionPricingTierId = isSubscriptionActive
    ? subscription?.metadata?.pricingTierId
    : null
  const subscriptionPricingTierId =
    subscriptionPricingData?.pricingTierId ??
    oldSubscriptionPricingTierId ??
    null
  if (
    typeof subscriptionPricingTierId === 'string' &&
    PRICING_TIER_IDS.includes(subscriptionPricingTierId as PricingTierId)
  ) {
    return subscriptionPricingTierId as PricingTierId
  }

  if (isSubscriptionActive) {
    const { pricingTierId } = parseSubscriptionPriceData(subscription)
    return pricingTierId
  }

  if (role === Role.ADMIN) {
    return PricingTierId.ENTERPRISE
  }
  if (role === Role.PARTNER) {
    return PricingTierId.GROWTH
  }

  return PricingTierId.FREE
}

export function getPricingPeriod(
  subscription?: Stripe.Subscription | null
): PricingPeriod | null {
  const { pricingPeriod } = parseSubscriptionPriceData(subscription)
  return pricingPeriod
}

export function getActivityOrFeatureLimitByTierId(
  tierId: PricingTierId | null,
  activityOrFeatureType: ActivityType | FeatureType
): PricingTierLimitType | null {
  return (
    TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT[tierId ?? PricingTierId.FREE][
      activityOrFeatureType
    ] ?? null
  )
}
