import { DEFAULT_MODEL_NAME, type LlmModelType } from '@/shared/constants/llm'
import { condenseNumber } from '@/shared/utils/number'
import { ActivityType, type IntegrationName } from '@prisma/client'

export enum PricingTierId {
  FREE = 'free',
  STARTER = 'starter',
  GROWTH = 'growth',
  SCALE = 'scale',
  ENTERPRISE = 'enterprise',
}

export enum PricingPeriod {
  Monthly = 'Monthly',
  Annually = 'Annually',
}

export enum UpdateFrequency {
  Daily = 'daily',
  Realtime = 'realtime',
}

type PricingTierData = {
  name: string
  monthlyPrice?: Record<PricingPeriod, number>
  description: string
  isMostPopular?: boolean
  isPerUser?: boolean
  isVisible?: boolean
  isComingSoon?: boolean
  isContactUsOnly?: boolean
  integrationSpecificData?: Partial<
    Record<
      IntegrationName,
      Partial<Omit<PricingTierData, 'integrationSpecificData'>> & {
        isVisible?: boolean
      }
    >
  >
}

// NOTE: Ordered by upgrade
export const PRICING_TIER_IDS = [
  PricingTierId.FREE,
  PricingTierId.STARTER,
  PricingTierId.GROWTH,
  PricingTierId.SCALE,
  PricingTierId.ENTERPRISE,
] as const

export enum FeatureType {
  KEYWORDS_MONITORED = 'KEYWORDS_MONITORED',
  INTEGRATION_COUNT = 'INTEGRATION_COUNT',
  AI_MODEL = 'AI_MODEL',
  UPDATE_FREQUENCY = 'UPDATE_FREQUENCY',
}

export type PricingActivityUsageType = {
  activityType: ActivityType
  max: number | null
  count: number
  progress: number
}

export const FREE_TRIAL_DAYS = 0 //7

export type PricingTierLimitType =
  | {
      count: number
      frequency?: 'month' | 'day'
    }
  | {
      name: string
      value: string | boolean | null
    }
  | null
export const TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT: Record<
  PricingTierId,
  Partial<Record<ActivityType | FeatureType, PricingTierLimitType>>
> = {
  [PricingTierId.FREE]: {
    [FeatureType.KEYWORDS_MONITORED]: {
      count: 5,
    },
    [ActivityType.KEYWORD_MATCH_PROCESSED]: {
      count: 100,
      frequency: 'month',
    },
    [ActivityType.COMMENT_PROCESSED]: {
      count: 100,
      frequency: 'month',
    },
    [ActivityType.COMMENT_GENERATED]: {
      count: 3,
      frequency: 'month',
    },
    [FeatureType.UPDATE_FREQUENCY]: {
      name: 'Daily',
      value: UpdateFrequency.Daily,
    },
    // @deprecated
    [ActivityType.TASK_PRIORITIZED]: {
      count: 25,
      frequency: 'month',
    },
    [FeatureType.INTEGRATION_COUNT]: {
      count: 3,
    },
    [FeatureType.AI_MODEL]: {
      name: 'Basic',
      value: DEFAULT_MODEL_NAME as LlmModelType,
    },
    [ActivityType.MESSAGE_PROCESSED]: {
      count: 250,
      frequency: 'month',
    },
    [ActivityType.EMAIL_PROCESSED]: {
      count: 100,
      frequency: 'month',
    },
    [ActivityType.CONTEXT_AGENT_USED]: {
      count: 250,
      frequency: 'month',
    },
    [ActivityType.SMART_ACTION_EDIT]: {
      count: 50,
      frequency: 'month',
    },
  },
  [PricingTierId.STARTER]: {
    [FeatureType.KEYWORDS_MONITORED]: {
      count: 5,
    },
    [ActivityType.KEYWORD_MATCH_PROCESSED]: {
      count: 500,
      frequency: 'month',
    },
    [ActivityType.COMMENT_PROCESSED]: {
      count: 200,
      frequency: 'month',
    },
    [ActivityType.COMMENT_GENERATED]: {
      count: 25,
      frequency: 'month',
    },
    [FeatureType.UPDATE_FREQUENCY]: {
      name: 'Real-time',
      value: UpdateFrequency.Realtime,
    },
    // @deprecated
    [ActivityType.TASK_PRIORITIZED]: {
      count: 100,
      frequency: 'month',
    },
    [ActivityType.MESSAGE_PROCESSED]: {
      count: 1000,
      frequency: 'month',
    },
    [ActivityType.EMAIL_PROCESSED]: {
      count: 500,
      frequency: 'month',
    },
    [ActivityType.CONTEXT_AGENT_USED]: {
      count: 500,
      frequency: 'month',
    },
    [ActivityType.SMART_ACTION_EDIT]: {
      count: 250,
      frequency: 'month',
    },
    [FeatureType.INTEGRATION_COUNT]: {
      count: 5,
    },
    [FeatureType.AI_MODEL]: {
      name: 'Advanced',
      value: null,
    },
  },
  [PricingTierId.GROWTH]: {
    [FeatureType.KEYWORDS_MONITORED]: {
      count: 10,
    },
    [ActivityType.KEYWORD_MATCH_PROCESSED]: {
      count: 3000,
      frequency: 'month',
    },
    [ActivityType.COMMENT_PROCESSED]: {
      count: 3000,
      frequency: 'month',
    },
    [ActivityType.COMMENT_GENERATED]: {
      count: 150,
      frequency: 'month',
    },
    [FeatureType.UPDATE_FREQUENCY]: {
      name: 'Real-time',
      value: UpdateFrequency.Realtime,
    },
  },
  [PricingTierId.SCALE]: {
    [ActivityType.KEYWORD_MATCH_PROCESSED]: {
      count: 30000,
      frequency: 'month',
    },
    [ActivityType.COMMENT_PROCESSED]: {
      count: 30000,
      frequency: 'month',
    },
    [ActivityType.COMMENT_GENERATED]: {
      count: 350,
      frequency: 'month',
    },
    [FeatureType.KEYWORDS_MONITORED]: {
      count: 25,
    },
    [FeatureType.UPDATE_FREQUENCY]: {
      name: 'Real-time',
      value: UpdateFrequency.Realtime,
    },
  },
  [PricingTierId.ENTERPRISE]: {
    [ActivityType.KEYWORD_MATCH_PROCESSED]: null,
    [ActivityType.COMMENT_PROCESSED]: null,
    [ActivityType.COMMENT_GENERATED]: null,
    [FeatureType.KEYWORDS_MONITORED]: null,
    [FeatureType.UPDATE_FREQUENCY]: {
      name: 'Real-time',
      value: UpdateFrequency.Realtime,
    },
  },
} as const

const getTierLimitText = (
  tierId: PricingTierId,
  activityOrFeatureType: ActivityType | FeatureType
) => {
  const limit =
    activityOrFeatureType in TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT[tierId] &&
    TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT[tierId][activityOrFeatureType]
  if (!limit) {
    return 'Unlimited'
  }

  if ('name' in limit) {
    return limit.name
  }

  return `${condenseNumber(limit.count)}${
    'frequency' in limit && limit.frequency !== 'month'
      ? `/${limit.frequency}`
      : ''
  }`
}
const getTierLimitTextMap = (
  activityOrFeatureType: ActivityType | FeatureType
) => {
  return PRICING_TIER_IDS.reduce(
    (acc, pricingTierId) => ({
      ...acc,
      [pricingTierId]: getTierLimitText(pricingTierId, activityOrFeatureType),
    }),
    {} as Record<PricingTierId, string>
  )
}

export const PRICING_TIER_DATA: Record<PricingTierId, PricingTierData> = {
  [PricingTierId.FREE]: {
    name: 'Free',
    // monthlyPrice: {
    //   [PricingPeriod.Monthly]: 0,
    //   [PricingPeriod.Annually]: 0,
    // },
    description: 'Get started with basic Reddit monitoring and engagement.',
  },
  [PricingTierId.STARTER]: {
    name: 'Starter',
    monthlyPrice: {
      [PricingPeriod.Monthly]: 9,
      [PricingPeriod.Annually]: 7, // $84/year
      // Changes:
      // 5/month, 50/year
      // Oct 8 2021: 29/month, 19/month/year, change name from "Premium"
      // Oct 13 2024: 9/month, 7/year
    },
    description: 'Boost your Reddit engagement and reach a wider audience.',
  },
  [PricingTierId.GROWTH]: {
    name: 'Growth',
    monthlyPrice: {
      [PricingPeriod.Monthly]: 49,
      [PricingPeriod.Annually]: 39, // $468/year
      // Changes:
      // 20/month, 228/year
      // Oct 8 2021: 99/month, 79/month/year, change name from "Team"
      // Oct 13 2024: 49/month, 39/month/year
    },
    description:
      'Build your brand on Reddit strategy with comprehensive tools.',
    isMostPopular: true,
  },
  [PricingTierId.SCALE]: {
    name: 'Scale',
    monthlyPrice: {
      [PricingPeriod.Monthly]: 99,
      [PricingPeriod.Annually]: 79, // $948/year
      // Changes:
      // Oct 8 2021: 249/month, 199/month/year, new
      // Oct 13 2024: 99/month, 79/month/year
    },
    description: 'Convert every relevant lead on Reddit at scale.',
  },
  [PricingTierId.ENTERPRISE]: {
    name: 'Enterprise',
    isContactUsOnly: true,
    description: 'Custom Reddit solutions tailored for your enterprise.',
    isVisible: true,
    // Changes:
    // Oct 8 2021: new
  },
} as const

export type FeatureDataType = {
  name: string
  description: string
  tiers: Record<PricingTierId, boolean | string | number>
  isVisible?: boolean
  integrationSpecificData?: Partial<
    Record<
      IntegrationName,
      Partial<Omit<FeatureDataType, 'integrationSpecificData'>> & {
        order?: number
      }
    >
  >
}

export const PRICING_FEATURE_DATA = {
  [ActivityType.KEYWORD_MATCH_PROCESSED]: {
    name: 'Keyword Matches Filtered',
    description:
      'Keyword matches are validated for relevance so you only see the most relevant conversations.',
    tiers: getTierLimitTextMap(ActivityType.KEYWORD_MATCH_PROCESSED),
  },
  [ActivityType.COMMENT_PROCESSED]: {
    name: 'Posts/Comments Filtered',
    description:
      'Posts and comments that are being replied to get filtered out if a reply isn’t necessary, saving you from unnecessary interactions.',
    tiers: getTierLimitTextMap(ActivityType.COMMENT_PROCESSED),
    isVisible: false,
  },
  [ActivityType.COMMENT_GENERATED]: {
    name: 'Replies Drafted',
    description:
      'Comments are expertly crafted for you to capture leads and build your brand.',
    tiers: getTierLimitTextMap(ActivityType.COMMENT_GENERATED),
  },
  [FeatureType.KEYWORDS_MONITORED]: {
    name: 'Keywords Monitored',
    description:
      'Your desired keywords are tracked for you 24/7 and the matches are ready for you to respond to.',
    tiers: getTierLimitTextMap(FeatureType.KEYWORDS_MONITORED),
  },
  [FeatureType.AI_MODEL]: {
    name: 'AI Model',
    description:
      'The AI model complexity determines understanding and response accuracy.',
    tiers: getTierLimitTextMap(FeatureType.AI_MODEL),
    isVisible: false,
  },
  [FeatureType.UPDATE_FREQUENCY]: {
    name: 'Email Notifications',
    description:
      'Email notifications for new posts and comments are sent to notify you only when relevant conversations are found.',
    tiers: getTierLimitTextMap(FeatureType.UPDATE_FREQUENCY),
  },
} as const satisfies Partial<
  Record<FeatureType | ActivityType, FeatureDataType>
>

export type PricingSection = {
  name: string
  features: FeatureDataType[]
  integrationSpecificData?: Partial<
    Record<
      IntegrationName,
      {
        isVisible?: boolean
      }
    >
  >
}
export const PRICING_SECTIONS: PricingSection[] = [
  {
    name: 'Features',
    features: [
      PRICING_FEATURE_DATA[FeatureType.KEYWORDS_MONITORED],
      PRICING_FEATURE_DATA[ActivityType.COMMENT_GENERATED],
      PRICING_FEATURE_DATA[ActivityType.KEYWORD_MATCH_PROCESSED],
      PRICING_FEATURE_DATA[ActivityType.COMMENT_PROCESSED],
      PRICING_FEATURE_DATA[FeatureType.UPDATE_FREQUENCY],
    ],
  },
  {
    name: 'Support',
    features: [
      // {
      //   name: 'Support Requests',
      //   description: 'Access support for any queries or issues you might have.',
      //   tiers: {
      //     [PricingTierId.FREE]: true,
      //     [PricingTierId.STARTER]: true,
      //     [PricingTierId.GROWTH]: true,
      //     [PricingTierId.PROFESSIONAL]: true,
      //     [PricingTierId.ELITE]: true,
      //     [PricingTierId.ENTERPRISE]: true,
      //   },
      // },
      {
        name: 'Email Support',
        description:
          'Get dedicated email support with a guaranteed response time under our Service Level Agreement (SLA).',
        tiers: {
          [PricingTierId.FREE]: false,
          [PricingTierId.STARTER]: '3-day SLA',
          [PricingTierId.GROWTH]: '2-day SLA',
          [PricingTierId.SCALE]: '1-day SLA',
          [PricingTierId.ENTERPRISE]: 'Priority',
        },
      },
      {
        name: 'Live Support',
        description: 'Access real-time support for immediate assistance.',
        tiers: {
          [PricingTierId.FREE]: false,
          [PricingTierId.STARTER]: false,
          [PricingTierId.GROWTH]: false,
          [PricingTierId.SCALE]: true,
          [PricingTierId.ENTERPRISE]: true,
        },
      },
      {
        name: '1:1 Onboarding',
        description:
          'Receive a personalized onboarding session to get the most out of the service.',
        tiers: {
          [PricingTierId.FREE]: false,
          [PricingTierId.STARTER]: false,
          [PricingTierId.GROWTH]: false,
          [PricingTierId.SCALE]: true,
          [PricingTierId.ENTERPRISE]: true,
        },
      },
      {
        name: 'Priority Feature Requests',
        description: 'Have your feature requests prioritized for development.',
        tiers: {
          [PricingTierId.FREE]: false,
          [PricingTierId.STARTER]: false,
          [PricingTierId.GROWTH]: false,
          [PricingTierId.SCALE]: true,
          [PricingTierId.ENTERPRISE]: true,
        },
      },
    ],
  },
] as const
