import { Logo } from '@/web/components/Logo'
import { NavButtonList } from '@/web/components/NavButtonList'
import { RedditSidebarActivity } from '@/web/components/RedditSidebarActivity'
import { ROUTE } from '@/web/constants/router'
import { useHasSidebar } from '@/web/hooks/use-has-sidebar'
import { useSidebarItems } from '@/web/hooks/useSidebarItems'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

export const MobileSidebar = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const hasSidebar = useHasSidebar()
  const sidebarItems = useSidebarItems()

  const router = useRouter()

  if (!hasSidebar) {
    return null
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50 lg:hidden' onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'>
            <Dialog.Panel className='relative flex w-full max-w-xs flex-1'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                  <button
                    type='button'
                    className='-m-2.5 p-2.5'
                    onClick={() => setIsOpen(false)}>
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white p-2'>
                <div className='flex w-full shrink-0 items-center justify-start'>
                  <Logo />
                </div>
                <NavButtonList
                  items={sidebarItems}
                  onClick={() => setIsOpen(false)}
                />
                {router.pathname.startsWith(ROUTE.REDDIT_DASHBOARD) && (
                  <RedditSidebarActivity />
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
