import { getProgressTheme } from '@/shared/utils/theme'
import { TextCounter } from '@/web/components/TextCounter'
import { Progress } from '@/web/components/ui/progress'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { cn } from '@/web/libs/utils'
import _ from 'lodash'
import { InfoIcon } from 'lucide-react'
import type { ComponentProps } from 'react'

export function UsageProgressBar({
  current,
  limit,
  title,
  isLoading,
  info,
  size,
}: {
  current?: number
  limit: number | null
  title: string
  isLoading: boolean
  info: string
  size?: ComponentProps<typeof Progress>['size']
}) {
  const progress = _.clamp(limit ? ((current ?? 0) / limit) * 100 : 100, 0, 100)
  const theme: ComponentProps<typeof Progress>['theme'] = getProgressTheme(
    limit && limit !== Number.POSITIVE_INFINITY ? progress / 100 : null
  )
  return (
    <div
      key={title}
      className={cn('space-y-2', size === 'sm' && 'space-y-0.5')}>
      <div className='flex items-center justify-between gap-x-2'>
        <div
          className={cn('flex items-center gap-2', size === 'sm' && 'gap-1')}>
          {size === 'sm' ? (
            <small className='text-muted-foreground line-clamp-1'>
              {title}
            </small>
          ) : (
            <h6 className='capitalize line-clamp-1'>{title}</h6>
          )}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className={cn('ml-2', size === 'sm' && 'ml-0')}>
                <span className='sr-only'>{info}</span>
                <InfoIcon
                  className={cn(
                    'text-muted-foreground/50 size-5',
                    size === 'sm' && 'size-2.5'
                  )}
                  aria-hidden='true'
                />
              </TooltipTrigger>
              <TooltipContent className='max-w-[80dvw]'>{info}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <TextCounter
          isCondensed
          count={current}
          max={limit ?? Number.POSITIVE_INFINITY}
          isLoading={isLoading}
          className={cn(
            'text-muted-foreground font-medium',
            size === 'sm' && 'text-2xs'
          )}
        />
      </div>
      <Progress
        value={progress}
        theme={theme}
        className={cn('w-full', isLoading && 'animate-pulse')}
        size={size}
      />
    </div>
  )
}
