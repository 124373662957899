import {
  SLACK_CONVERSATION_TYPE_NAME,
  SLACK_CONVERSATION_TYPES,
  SlackSettingsInputSchema,
  type SlackConversation,
  type SlackConversationType,
  type SlackIntegrationConversationsSettingsType,
  type SlackIntegrationSettingsType,
} from '@/shared/constants/slack'
import { getConversationTypePrefix } from '@/shared/utils/slack'
import { Button } from '@/web/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/web/components/ui/form'
import { ScrollArea } from '@/web/components/ui/scroll-area'
import { Skeleton } from '@/web/components/ui/skeleton'
import { Switch } from '@/web/components/ui/switch'
import { type CustomSettingsPropsType } from '@/web/constants/integration'
import { useIntegration } from '@/web/hooks/use-integration'
import { api } from '@/web/utils/api'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { IntegrationName } from '@prisma/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'

function createConversationSettings(
  conversations?: SlackConversation[],
  settings?: SlackIntegrationConversationsSettingsType,
  update?: { conversationId?: string; isEnabled: boolean }
): SlackIntegrationConversationsSettingsType {
  const conversationSettings = {} as SlackIntegrationConversationsSettingsType

  conversations?.forEach(conversation => {
    conversationSettings[conversation.id] =
      update?.conversationId === conversation.id
        ? update.isEnabled
        : update?.conversationId === undefined && update?.isEnabled != null
        ? update?.isEnabled
        : (settings && settings[conversation.id]) ?? false
  })

  console.log('Created conversation settings', conversationSettings)

  return conversationSettings
}

export function SlackSettings(props: CustomSettingsPropsType) {
  const { integration: slackIntegration } = useIntegration(
    IntegrationName.SLACK
  )
  const slackConversationSettings =
    slackIntegration?.settings &&
    typeof slackIntegration?.settings === 'object' &&
    'conversations' in slackIntegration?.settings
      ? (slackIntegration.settings
          .conversations as SlackIntegrationConversationsSettingsType)
      : {}

  useEffect(() => {
    console.log('Slack access token changed - refreshing conversations', {
      accessToken: slackIntegration?.accessToken,
    })
    utils.slack.listConversations.invalidate()
  }, [slackIntegration?.accessToken])

  const conversationsQueryResult = api.slack.listConversations.useQuery()
  const conversations = conversationsQueryResult.data

  const utils = api.useUtils()
  const updateSlackSettingsMutationResult =
    api.slack.updateSettings.useMutation({
      onSuccess: () => {
        utils.integration.list.invalidate()
      },
    })

  const form = useForm<z.infer<typeof SlackSettingsInputSchema>>({
    resolver: zodResolver(SlackSettingsInputSchema),
    defaultValues: {
      conversations: {},
    },
  })

  const onSubmit = (data: z.infer<typeof SlackSettingsInputSchema>) => {
    console.log('Saving settings', data)
    updateSlackSettingsMutationResult.mutate(data)
  }

  // Save form on change
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [form.handleSubmit, form.watch])

  // Update isEnabled
  useEffect(() => {
    if (!slackIntegration) {
      return
    }

    const slackIntegrationSettings =
      slackIntegration.settings as SlackIntegrationSettingsType
    if (
      !slackIntegrationSettings ||
      typeof slackIntegrationSettings !== 'object'
    ) {
      console.log(
        'Slack integration data is not an object',
        slackIntegrationSettings
      )
      return
    }

    console.log('Updating conversation settings', slackIntegrationSettings)
    form.reset(slackIntegrationSettings)
  }, [conversations])

  if (Object.values(form.formState.errors).length > 0) {
    console.log('Errors:', form.formState.errors)
  }

  useEffect(() => {
    if (
      !conversationsQueryResult.data?.length &&
      !conversationsQueryResult.isLoading
    ) {
      console.log('No conversations found')
      props?.onEmpty?.()
    }
  }, [conversationsQueryResult.data, conversationsQueryResult.isLoading])

  if (conversationsQueryResult.isLoading) {
    return <Skeleton className='h-48 w-full' />
  }

  if (!conversationsQueryResult.data?.length) {
    return null
  }

  console.log('Rendering SlackSettings')

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <>
          <FormMessage>
            {form.formState.errors.conversations?.conversations?.message}
          </FormMessage>
          <FormField
            control={form.control}
            name='conversations'
            render={({ field }) => (
              <FormItem className='space-y-4'>
                <div>
                  <FormLabel className='text-base'>
                    <h5>Conversations</h5>
                  </FormLabel>
                  <FormDescription>
                    Select the conversations you want to analyze.
                  </FormDescription>
                </div>
                <div className='grid gap-4 md:grid-cols-2'>
                  <Button
                    variant='outline'
                    theme='success'
                    onClick={() =>
                      form.setValue(
                        'conversations',
                        createConversationSettings(
                          conversations,
                          {},
                          { isEnabled: true }
                        )
                      )
                    }
                    disabled={conversations?.every(
                      conversation =>
                        slackConversationSettings[conversation.id] === true
                    )}>
                    <FontAwesomeIcon icon={faCheckCircle} /> Enable All
                    Conversations
                  </Button>
                  <Button
                    variant='outline'
                    theme='destructive'
                    onClick={() =>
                      form.setValue(
                        'conversations',
                        createConversationSettings(
                          conversations,
                          {},
                          { isEnabled: false }
                        )
                      )
                    }
                    disabled={conversations?.every(
                      conversation =>
                        slackConversationSettings[conversation.id] !== true
                    )}>
                    <FontAwesomeIcon icon={faTimesCircle} /> Disable All
                    Conversations
                  </Button>
                </div>
                <div className='grid grid-cols-1 gap-6 2xl:grid-cols-3'>
                  {SLACK_CONVERSATION_TYPES.map(conversationType => {
                    const conversationTypeName =
                      SLACK_CONVERSATION_TYPE_NAME[conversationType]
                    const typeConversations = conversations?.filter(
                      conversation => conversation.type === conversationType
                    )
                    if (!typeConversations?.length) {
                      return null
                    }

                    const enabledConversationCount = typeConversations.filter(
                      conversation =>
                        slackConversationSettings[conversation.id] === true
                    ).length

                    return (
                      <div key={conversationType} className='space-y-4'>
                        <p className='text-muted-foreground flex flex-row items-baseline gap-2'>
                          <span>{conversationTypeName}s</span>
                          <small>
                            {enabledConversationCount} of{' '}
                            {typeConversations.length} enabled
                          </small>
                        </p>
                        <ScrollArea
                          type='always'
                          className='bg-base-200 h-64 max-h-fit rounded-sm shadow-inner'>
                          <section className='space-y-2 p-2 '>
                            {typeConversations
                              ?.slice(0)
                              .sort(({ name: a }, { name: b }) =>
                                (a || '') < (b || '') ? -1 : 1
                              )
                              .map(({ id, name, type, purpose }) => (
                                <FormItem
                                  key={id}
                                  className='flex flex-row items-start justify-start gap-2'>
                                  <FormControl>
                                    <Switch
                                      id={id}
                                      theme='success'
                                      checked={
                                        field.value
                                          ? field.value[id] ?? false
                                          : false
                                      }
                                      onCheckedChange={checked => {
                                        const updatedValue =
                                          createConversationSettings(
                                            conversations,
                                            field.value,
                                            {
                                              isEnabled: checked,
                                              conversationId: id,
                                            }
                                          )
                                        console.log(
                                          'Updating conversations',
                                          updatedValue
                                        )
                                        field.onChange(updatedValue)
                                      }}
                                    />
                                  </FormControl>
                                  <FormLabel
                                    htmlFor={id}
                                    className='flex flex-col self-center'>
                                    {getConversationTypePrefix(
                                      type as SlackConversationType
                                    )}
                                    {name}
                                    {purpose ? (
                                      <small className='text-muted-foreground'>
                                        {purpose}
                                      </small>
                                    ) : null}
                                  </FormLabel>
                                </FormItem>
                              ))}
                          </section>
                        </ScrollArea>
                      </div>
                    )
                  })}
                </div>
              </FormItem>
            )}
          />
        </>
      </form>
    </Form>
  )
}
