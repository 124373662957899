import { GMAIL_SCOPES } from '@/shared/constants/gmail'
import { GOOGLE_CALENDAR_SCOPES } from '@/shared/constants/google-calendar'
import {
  INTEGRATION_BASIC_DATA,
  type IntegrationBasicData,
} from '@/shared/constants/integration'
import { JIRA_SCOPES } from '@/shared/constants/jira'
import { SLACK_BOT_SCOPES, SLACK_USER_SCOPES } from '@/shared/constants/slack'
import { SCOPES as YOUTUBE_SCOPES } from '@/shared/constants/youtube'
import { generateAuthUrl } from '@/shared/utils/integrations'
import { GmailSettings } from '@/web/components/GmailSettings'
import { GoogleCalendarSettings } from '@/web/components/GoogleCalendarSettings'
import { ConfluenceIcon } from '@/web/components/icons/ConfluenceIcon'
import { GmailIcon } from '@/web/components/icons/GmailIcon'
import { GoogleCalendarIcon } from '@/web/components/icons/GoogleCalendarIcon'
import { JiraIcon } from '@/web/components/icons/JiraIcon'
import { MicrosoftTeamsIcon } from '@/web/components/icons/MicrosoftTeamsIcon'
import { NotionIcon } from '@/web/components/icons/NotionIcon'
import { OutlookIcon } from '@/web/components/icons/OutlookIcon'
import { SlackIcon } from '@/web/components/icons/SlackIcon'
import { YoutubeIcon } from '@/web/components/icons/YoutubeIcon'
import { JiraSettings } from '@/web/components/JiraSettings'
import { NotionSettings } from '@/web/components/NotionSettings'
import { RedditSettings } from '@/web/components/RedditSettings'
import { SlackSettings } from '@/web/components/SlackSettings'
import { ROUTE } from '@/web/constants/router'
import { env } from '@/web/env'
import { api } from '@/web/utils/api'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckCircle,
  faComment,
  faPaperPlane,
} from '@fortawesome/free-regular-svg-icons'
import {
  faBell,
  faCalendarAlt,
  faCancel,
  faLayerGroup,
  faList,
} from '@fortawesome/free-solid-svg-icons'
import { IntegrationName } from '@prisma/client'
import {
  BotMessageSquare,
  MegaphoneIcon,
  SquareArrowUpIcon,
} from 'lucide-react'
import { type Route } from 'next'
import React from 'react'
import { RedditIcon } from '../components/icons/RedditIcon'

// TODO: Make private and only allow access to redirect UI from INTEGRATION_DATA
export function getRedirectUri(
  integrationName: IntegrationName,
  options: {
    baseUrl?: string
    isForcingHttps?: boolean
    customPath?: string
  } = {}
): string {
  // Destructure with default value for baseUrl
  const {
    baseUrl = env.NEXT_PUBLIC_HOST,
    isForcingHttps = false,
    customPath,
  } = options

  let url = baseUrl

  // If isForcingHttps is true, change the protocol to https
  if (isForcingHttps) {
    url = url.replace(/^http:/, 'https:')
  }

  if (customPath) {
    return url + customPath
  }

  return url + `/integrations/${integrationName.toLowerCase()}`
}

export type CustomSettingsPropsType = {
  onEmpty?: () => void
}
export type IntegrationData = IntegrationBasicData & {
  connectMutation?: typeof api.jira.integrate
  deleteMutation?: typeof api.slack.revoke
  permissionsQuery?:
    | typeof api.jira.permissions
    | typeof api.gmail.permissions
    | typeof api.slack.permissions
    | typeof api.googleCalendar.permissions
    | typeof api.youtube.permissions
    | typeof api.reddit.permissions
  CustomSettings?: (props: CustomSettingsPropsType) => React.JSX.Element | null
  image?: string
  Icon: ({
    isColored,
    ...props
  }: {
    isColored?: boolean
    className?: string
    width?: number
    height?: number
  }) => JSX.Element
  authHref?: string
  benefits?: {
    title: string
    description: string
    icon?: JSX.Element
  }[]
  features?: {
    title: string
    description: string
    icon?: IconProp
  }[]
}

export const INTEGRATION_DATA: Record<IntegrationName, IntegrationData> = {
  [IntegrationName.REDDIT]: {
    ...INTEGRATION_BASIC_DATA.REDDIT,
    Icon: props => <RedditIcon {...props} />,
    connectMutation: api.reddit.integrate,
    CustomSettings: RedditSettings,
    permissionsQuery: api.reddit.permissions,
    authHref: generateAuthUrl(
      {
        baseUrl: 'https://www.reddit.com/api/v1/authorize',
        clientId: env.NEXT_PUBLIC_REDDIT_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.REDDIT, {
          customPath: ROUTE.REDDIT_DASHBOARD,
        }),
        scopes: INTEGRATION_BASIC_DATA.REDDIT.scopes,
      },
      {
        duration: 'permanent',
        state: 'reddit',
      }
    ),
    benefits: [
      {
        title: 'Increase Your Karma',
        description:
          'Engage with relevant subreddits to boost your credibility.',
        icon: <SquareArrowUpIcon className='size-full' />,
      },
      {
        title: 'Promote Tastefully',
        description:
          'Share your expertise and promote your business without spamming.',
        icon: <MegaphoneIcon className='size-full' />,
      },
      {
        title: 'Automate Engagement',
        description:
          'Save time by automating comments and replies to capture leads instantly.',
        icon: <BotMessageSquare className='size-full' />,
      },
      // {
      //   title: 'Community Engagement',
      //   description:
      //     'Engage with your target audience on Reddit to build brand awareness and loyalty.',
      // },
      // {
      //   title: 'Lead Generation',
      //   description:
      //     'Generate leads by engaging with users in subreddits relevant to your business.',
      // },
      // {
      //   title: 'Content Promotion',
      //   description:
      //     'Promote your business in relevant conversations to drive traffic to your website.',
      // },
      // {
      //   title: 'Spam Prevention',
      //   description:
      //     'Prevent your account from being marked as spam and banned.',
      // },
    ],
    // features: [
    //   {
    //     title: 'Post Commenting',
    //     description:
    //       'Comment on posts in the subreddits relevant to your business to grow your karma. Promote your business in the process without spamming.',
    //   },
    //   {
    //     title: 'Comment Replying',
    //     description:
    //       'Reply to users who engage with your comments to grow your karma. Promote your business in the process without spamming.',
    //   },
    //   {
    //     title: 'Keyword Monitoring',
    //     description:
    //       'Monitor keywords relevant your business and respond instantly to capture leads. Promote your business in the process without spamming.',
    //   },
    // {
    //   title: 'Post Submission (coming soon)',
    //   description:
    //     'Submit posts to relevant subreddits grow your karma. Cross-post to multiple subreddits to reach a wider audience. Promote your business in the process without spamming.',
    // },
    // ],
  },
  [IntegrationName.GMAIL]: {
    ...INTEGRATION_BASIC_DATA.GMAIL,
    Icon: props => <GmailIcon {...props} />,
    authHref: generateAuthUrl(
      {
        baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: env.NEXT_PUBLIC_GMAIL_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.GMAIL),
        scopes: GMAIL_SCOPES,
      },
      {
        access_type: 'offline',
        prompt: 'consent',
      }
    ),
    permissionsQuery: api.gmail.permissions,
    connectMutation: api.gmail.integrate,
    CustomSettings: GmailSettings,
    // image: '/images/gmail.webp',
    benefits: [
      {
        title: 'Email Summarization',
        description:
          'Receive concise summaries of your important emails, ensuring you never miss vital information.',
      },
      {
        title: 'Priority Sorting',
        description:
          'Automatically sort emails based on importance and relevance.',
      },
      {
        title: 'Email Activity Monitoring',
        description:
          'Get an overview of your email activity, including sent, received, unread, spam, and snoozed.',
      },
      {
        title: 'Trend Detection',
        description: 'Identify recurring themes or topics in your inbox.',
      },
    ],
    features: [
      {
        title: 'Inbox Summarization',
        description:
          'Get a concise summary of your inbox, ensuring you never miss important information.',
      },
      {
        title: 'Email Drafting',
        description: 'Respond to your emails without writing a word.',
      },
      {
        title: 'Automatic Archiving',
        description: 'Archive emails that have been summarized for you.',
      },
    ],
  },
  [IntegrationName.OUTLOOK]: {
    ...INTEGRATION_BASIC_DATA.OUTLOOK,
    Icon: props => <OutlookIcon {...props} />,
    // authHref: generateAuthUrl(
    //   {
    //     baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    //     clientId: env.NEXT_PUBLIC_OUTLOOK_CLIENT_ID,
    //     redirectUri: getRedirectUri(IntegrationName.OUTLOOK),
    //     scopes: OUTLOOK_SCOPES,
    //   },
    //   {
    //     access_type: 'offline',
    //     prompt: 'consent',
    //   }
    // ),
    // permissionsQuery: api.outlook.permissions,
    // connectMutation: api.outlook.integrate,
    // CustomSettings: OutlookSettings,
    // image: '/images/outlook.webp',
    benefits: [
      {
        title: 'Email Summarization',
        description:
          'Receive concise summaries of your important emails, ensuring you never miss vital information.',
      },
      {
        title: 'Priority Sorting',
        description:
          'Automatically sort emails based on importance and relevance.',
      },
      {
        title: 'Email Activity Monitoring',
        description:
          'Get an overview of your email activity, including sent, received, unread, spam, and snoozed.',
      },
      {
        title: 'Trend Detection',
        description: 'Identify recurring themes or topics in your inbox.',
      },
    ],
    features: [
      {
        title: 'Inbox Summarization',
        description:
          'Get a concise summary of your inbox, ensuring you never miss important information.',
      },
      {
        title: 'Email Drafting',
        description: 'Respond to your emails without writing a word.',
      },
      {
        title: 'Automatic Archiving',
        description: 'Archive emails that have been summarized for you.',
      },
    ],
  },
  [IntegrationName.GOOGLE_CALENDAR]: {
    ...INTEGRATION_BASIC_DATA.GOOGLE_CALENDAR,
    Icon: props => <GoogleCalendarIcon {...props} />,
    authHref: generateAuthUrl(
      {
        baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: env.NEXT_PUBLIC_GOOGLE_CALENDAR_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.GOOGLE_CALENDAR),
        scopes: GOOGLE_CALENDAR_SCOPES,
      },
      {
        access_type: 'offline',
        prompt: 'consent',
      }
    ),
    connectMutation: api.googleCalendar.integrate,
    permissionsQuery: api.googleCalendar.permissions,
    CustomSettings: GoogleCalendarSettings,
    features: [
      {
        title: 'Event Synchronization',
        description:
          'Effortlessly create, update, and synchronize your Google Calendar events with your tasks.',
        icon: faCalendarAlt,
      },
      {
        title: 'Event Context',
        description:
          'See details about your related Google Calendar events directly in your tasks.',
        icon: faBell,
      },
      {
        title: 'Calendar Selection',
        description:
          'Choose which Google Calendars you want to integrate with.',
        icon: faList,
      },
    ],
  },
  [IntegrationName.JIRA]: {
    ...INTEGRATION_BASIC_DATA.JIRA,
    Icon: props => <JiraIcon {...props} />,
    // authHref: env.NEXT_PUBLIC_JIRA_AUTH_URL as Route,
    // @see https://developer.atlassian.com/cloud/jira/platform/oauth-2-3lo-apps/
    // @see https://developer.atlassian.com/console/myapps/699a7b83-a14c-4f26-9c11-9608caf2519a/permissions/41ee78b2-a82d-4c80-8ee0-3cc89ee03277/scopes
    CustomSettings: JiraSettings,
    authHref: generateAuthUrl(
      {
        clientId: env.NEXT_PUBLIC_JIRA_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.JIRA),
        baseUrl: 'https://auth.atlassian.com/authorize',
        scopes: JIRA_SCOPES,
      },
      { prompt: 'consent' }
    ) as Route,
    permissionsQuery: api.jira.permissions,
    connectMutation: api.jira.integrate,
    features: [
      {
        title: 'Issue Synchronization',
        description:
          'Easily create and update Jira issues based on tasks and discussions across the other integrated tools.',
      },
      {
        title: 'Status Updates',
        description:
          'Get updates about the status of Jira issues right within Pulse.',
      },
      {
        title: 'Project Summarization',
        description:
          'Receive straightforward summaries of your Jira projects, helping you keep track of progress and developments in a simple, digestible format.',
      },
      {
        title: 'Workflow Selection',
        description:
          'Choose which Jira projects and issues Pulse keeps track of, giving you the flexibility to focus on what matters most.',
      },
    ],
  },
  [IntegrationName.NOTION]: {
    ...INTEGRATION_BASIC_DATA.NOTION,
    Icon: props => <NotionIcon {...props} />,
    connectMutation: api.notion.integrate,
    CustomSettings: NotionSettings,
    authHref: generateAuthUrl(
      {
        baseUrl: 'https://api.notion.com/v1/oauth/authorize',
        clientId: env.NEXT_PUBLIC_NOTION_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.NOTION),
      },
      {
        owner: 'user',
      }
    ),
    features: [
      {
        title: 'Context Gathering',
        description:
          'Pulse searches through your Notion to gather context of your task.',
      },
      {
        title: 'Privacy Controls',
        description:
          'Ensure specific Notion pages or databases remain private and unanalyzed if needed.',
      },
    ],
  },
  [IntegrationName.CONFLUENCE]: {
    ...INTEGRATION_BASIC_DATA.CONFLUENCE,
    Icon: props => <ConfluenceIcon {...props} />,
    features: [
      {
        title: 'Context Gathering',
        description:
          'Pulse searches through your Confluence to gather context of your task.',
      },
      {
        title: 'Privacy Controls',
        description:
          'Ensure specific Confluence pages or databases remain private and unanalyzed if needed.',
      },
    ],
  },
  [IntegrationName.SLACK]: {
    ...INTEGRATION_BASIC_DATA.SLACK,
    Icon: props => <SlackIcon {...props} />,
    authHref: generateAuthUrl(
      {
        baseUrl: 'https://slack.com/oauth/v2/authorize',
        clientId: env.NEXT_PUBLIC_SLACK_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.SLACK, {
          isForcingHttps: true,
        }),
      },
      {
        scope: SLACK_BOT_SCOPES.join(','),
        access_type: 'offline',
        user_scope: SLACK_USER_SCOPES.join(','),
      }
    ),
    connectMutation: api.slack.integrate,
    deleteMutation: api.slack.revoke,
    permissionsQuery: api.slack.permissions,
    CustomSettings: SlackSettings,
    features: [
      {
        title: 'Task Extraction',
        description:
          'Create and prioritize your tasks directly from your Slack conversations without any extra effort.',
        icon: faCheckCircle,
      },
      {
        title: 'Automated Responses',
        description:
          'Send pre-written messages to your conversations with a single click.',
        icon: faPaperPlane,
      },
      {
        title: 'Summarization',
        description: 'Get concise summaries of your conversations.',
        icon: faList,
      },
      {
        title: 'Conversation Selection',
        description: 'Choose which channels to monitor and analyze.',
        icon: faLayerGroup,
      },
    ],
  },
  [IntegrationName.MICROSOFT_TEAMS]: {
    ...INTEGRATION_BASIC_DATA.MICROSOFT_TEAMS,
    Icon: props => <MicrosoftTeamsIcon {...props} />,
    features: [
      {
        title: 'Task Extraction',
        description:
          'Create and prioritize your tasks directly from your Microsoft Teams conversations without any extra effort.',
        icon: faCheckCircle,
      },
      {
        title: 'Automated Responses',
        description:
          'Send pre-written messages to your conversations with a single click.',
        icon: faPaperPlane,
      },
      {
        title: 'Summarization',
        description: 'Get concise summaries of your conversations.',
        icon: faList,
      },
      {
        title: 'Conversation Selection',
        description: 'Choose which channels to monitor and analyze.',
        icon: faLayerGroup,
      },
    ],
  },
  [IntegrationName.YOUTUBE]: {
    ...INTEGRATION_BASIC_DATA.YOUTUBE,
    Icon: props => <YoutubeIcon {...props} />,
    authHref: generateAuthUrl(
      {
        baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: env.NEXT_PUBLIC_YOUTUBE_CLIENT_ID,
        redirectUri: getRedirectUri(IntegrationName.YOUTUBE),
        scopes: YOUTUBE_SCOPES,
      },
      {
        access_type: 'offline',
        prompt: 'consent',
      }
    ),
    permissionsQuery: api.youtube.permissions,
    connectMutation: api.youtube.integrate,
    features: [
      {
        title: 'Automated Comment Responses',
        description:
          'Edit and send pre-written responses to your YouTube comments with a single click.',
        icon: faComment,
      },
      {
        title: 'Comment Management',
        description:
          'Mark a comment as spam, delete your comments, and ban users from your channel.',
        icon: faCancel,
      },
    ],
  },
}
