'use client'

import {
  DEFAULT_ROOT_AUTHENTICATED_ROUTE,
  DEFAULT_ROOT_UNAUTHENTICATED_ROUTE,
} from '@/web/constants/router'
import {
  getRootAuthenticatedPath,
  getRootUnauthenticatedPath,
} from '@/web/utils/router'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useRootRoute = ({
  type,
}: {
  type?: 'authenticated' | 'unauthenticated'
} = {}) => {
  const session = useSession()
  const defaultRoute =
    type === 'authenticated'
      ? DEFAULT_ROOT_AUTHENTICATED_ROUTE
      : DEFAULT_ROOT_UNAUTHENTICATED_ROUTE
  const [href, setHref] = useState(defaultRoute)
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const previousRouteEntries =
      ('navigation' in window &&
        typeof window.navigation === 'object' &&
        !!window.navigation &&
        'entries' in window.navigation &&
        typeof window.navigation.entries === 'function' &&
        window.navigation.entries()) ||
      null
    const previousPathnames =
      (previousRouteEntries
        ?.map(
          (entry: unknown) =>
            (typeof entry === 'object' &&
              entry &&
              'url' in entry &&
              typeof entry.url === 'string' &&
              new URL(entry.url).pathname) ||
            null
        )
        .filter(Boolean)
        .reverse() as string[]) ?? []

    const isAuthenticated = session.status === 'authenticated'
    const isAuthenticatedRoute =
      (isAuthenticated && !type) || type === 'authenticated'

    const rootPath = isAuthenticatedRoute
      ? getRootAuthenticatedPath(router.pathname)
      : getRootUnauthenticatedPath(router.pathname)

    if (rootPath) {
      setHref(rootPath)
      return
    }

    let previousRootPath: string | null = null
    for (const previousPathname of previousPathnames) {
      previousRootPath = isAuthenticatedRoute
        ? getRootAuthenticatedPath(previousPathname)
        : getRootUnauthenticatedPath(previousPathname)

      if (previousRootPath) {
        break
      }
    }

    setHref(previousRootPath ?? defaultRoute)
  }, [session.status, router.isReady, router.pathname])

  return href
}
