import {
  ROOT_AUTHENTICATED_ROUTES,
  ROOT_ROUTE_AUTHENTICATED_UNAUTHENTICATED_MAP,
  ROOT_ROUTE_UNAUTHENTICATED_AUTHENTICATED_MAP,
  ROOT_ROUTES,
  ROOT_UNAUTHENTICATED_ROUTES,
} from '@/web/constants/router'
import type { Route } from 'next'

export function isRootPathMatch(path: string, route: Route): boolean {
  return route === path || (route !== '/' && path.startsWith(route))
}
export function getRootPathMatch(path: string, routes: Route[]): Route | null {
  return routes.find(route => isRootPathMatch(path, route)) ?? null
}
export function hasRootPathMatch(path: string, routes: Route[]): boolean {
  return routes.some(route => isRootPathMatch(path, route))
}

export function getRootPath(path: string): Route | null {
  return getRootPathMatch(path, ROOT_ROUTES)
}
export function getRootAuthenticatedPath(path: string): Route | null {
  const rootAuthenticatedRoute = getRootPathMatch(
    path,
    ROOT_AUTHENTICATED_ROUTES
  )
  if (rootAuthenticatedRoute) {
    return rootAuthenticatedRoute
  }

  const rootUnauthenticatedRoute = getRootPathMatch(
    path,
    ROOT_UNAUTHENTICATED_ROUTES
  )
  if (
    rootUnauthenticatedRoute &&
    ROOT_ROUTE_UNAUTHENTICATED_AUTHENTICATED_MAP[rootUnauthenticatedRoute]
  ) {
    return ROOT_ROUTE_UNAUTHENTICATED_AUTHENTICATED_MAP[
      rootUnauthenticatedRoute
    ]
  }

  return null
}
export function getRootUnauthenticatedPath(path: string): Route | null {
  const rootUnauthenticatedRoute = getRootPathMatch(
    path,
    ROOT_UNAUTHENTICATED_ROUTES
  )
  if (rootUnauthenticatedRoute) {
    return rootUnauthenticatedRoute
  }

  const rootAuthenticatedRoute = getRootPathMatch(
    path,
    ROOT_AUTHENTICATED_ROUTES
  )
  if (
    rootAuthenticatedRoute &&
    ROOT_ROUTE_AUTHENTICATED_UNAUTHENTICATED_MAP[rootAuthenticatedRoute]
  ) {
    return ROOT_ROUTE_AUTHENTICATED_UNAUTHENTICATED_MAP[rootAuthenticatedRoute]
  }

  return null
}
