export const COMPANY_NAME = 'Pulse'
export const COMPANY_TITLE = 'Respond to your notifications effortlessly.'
export const COMPANY_SLOGAN = 'Respond to your notifications effortlessly.'
export const COMPANY_DESCRIPTION =
  'Pulse uses your messages, emails, calendar, and notes to prioritize, contextualize, and respond to notifications and tasks.'
export const DOMAIN = 'https://usepulse.ai'
export const PRIVACY_URL = 'https://usepulse.ai/privacy'
export const TERMS_URL = 'https://usepulse.ai/terms'
export const SUPPORT_URL = 'https://usepulse.ai/support'
export const SUPPORT_EMAIL = 'support@usepulse.ai'
export const GOOGLE_WORKSPACE_MARKETPLACE_URL =
  'https://workspace.google.com/marketplace/app/pulse_for_gmail_reply_react_to_your_emai/337524070130'

export type LogoType = {
  src: string
  title: string
}

// GMAIL
export const GMAIL_TITLE_SIMPLE = 'Pulse for Gmail'
export const GMAIL_TITLE =
  'Pulse for Gmail: Autodrafting & Email Management for Gmail'
export const GMAIL_SLOGAN = 'Pulse for Gmail: Transform Your Email Experience'
export const GMAIL_DESCRIPTION =
  'Clear your inbox faster than ever with automatic reply drafting, detailed email summaries, quick action buttons, and much more!'
export const GMAIL_URL = 'https://usepulse.ai/gmail'
export const GMAIL_COMPANY_LOGOS: LogoType[] = [
  {
    src: '/images/companies/vercel.svg',
    title: 'Vercel',
  },
  {
    src: '/images/companies/google.svg',
    title: 'Google',
  },
  {
    src: '/images/companies/openai.svg',
    title: 'Open AI',
  },
  {
    src: '/images/companies/atlassian.svg',
    title: 'Atlassian',
  },
  {
    src: '/images/companies/stripe.svg',
    title: 'Stripe',
  },
]

// REDDIT
export const REDDIT_SLOGAN =
  'Pulse for Reddit: Transform Conversations into Customers'
export const REDDIT_TITLE_SIMPLE = 'Pulse for Reddit'
export const REDDIT_TITLE = 'Pulse for Reddit: Social Monitoring Tool'
export const REDDIT_DESCRIPTION =
  'Pulse for Reddit helps you identify Reddit users seeking your product and craft personalized responses to convert them into customers.'
export const REDDIT_URL = 'https://usepulse.ai/reddit'
// export const REDDIT_COMPANY_LOGOS: LogoType[] = [
//   {
//     src: '/images/companies/vercel.svg',
//     title: 'Vercel',
//   },
//   {
//     src: '/images/companies/google.svg',
//     title: 'Google',
//   },
//   {
//     src: '/images/companies/openai.svg',
//     title: 'Open AI',
//   },
//   {
//     src: '/images/companies/atlassian.svg',
//     title: 'Atlassian',
//   },
//   {
//     src: '/images/companies/stripe.svg',
//     title: 'Stripe',
//   },
// ]

// YOUTUBE

export const YOUTUBE_TITLE = 'Pulse for Youtube: Comment Management for Youtube'
export const YOUTUBE_TITLE_SIMPLE = 'Pulse for Youtube'

// SLACK
export const SLACK_TITLE = 'Pulse for Slack: Conversation Management for Slack'
export const SLACK_TITLE_SIMPLE = 'Pulse for Slack'

// SLACK
export const TASKS_TITLE =
  'Pulse for Task Management: Manage Your Tasks & Email Together'
export const TASKS_TITLE_SIMPLE = 'Pulse for Tasks'
