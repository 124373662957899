// import { PrismaClient } from '@prisma/client'

// type ModelName = keyof PrismaClient['_dmmf']['modelMap']
// const prisma = new PrismaClient()

// function getUniqueFields(modelName: ModelName): string[] {
//   const model = prisma._dmmf.modelMap[modelName]
//   return model.uniqueFields.flat()
// }

// function getModelNames(): ModelName[] {
//   return Object.keys(prisma._dmmf.modelMap) as ModelName[]
// }

// console.log('models', getModelNames())

export const TYPENAME_ID_FIELDS = {
  Settings: ['userId'],
  Integration: ['userId', 'name'],
}
export type TypenameType = keyof typeof TYPENAME_ID_FIELDS
