import {
  type GmailContextDataType,
  type GmailEmailType,
} from '@/shared/constants/gmail'
import {
  type GoogleCalendarContextDataType,
  type GoogleCalendarEventType,
} from '@/shared/constants/google-calendar'
import {
  type NotionContextDataType,
  type NotionPage,
} from '@/shared/constants/notion'
import type {
  RedditCommentType,
  RedditPostType,
} from '@/shared/constants/reddit'
import {
  type SlackContextDataType,
  type SlackConversation,
} from '@/shared/constants/slack'
import type {
  YoutubeCommentType,
  YoutubeVideoType,
} from '@/shared/constants/youtube'
import { IntegrationName, type Context, type ContextItem } from '@prisma/client'
import { z } from 'zod'

export type ContextMetadataType = {
  searchQuery: string
  reason: string
}

export type ContextDataType =
  | SlackContextDataType
  | GmailContextDataType
  | GoogleCalendarContextDataType
  | NotionContextDataType
export type AllContextDataType = Partial<
  SlackContextDataType &
    GmailContextDataType &
    GoogleCalendarContextDataType &
    NotionContextDataType
>
export const SUPPORTED_TASK_CONTEXT_INTEGRATION_NAMES: IntegrationName[] = [
  IntegrationName.SLACK,
  IntegrationName.GMAIL,
  IntegrationName.GOOGLE_CALENDAR,
  IntegrationName.NOTION,
] as const
export type IntegrationContextDatumType<
  T extends IntegrationName = IntegrationName,
> = T extends (typeof IntegrationName)['YOUTUBE']
  ?
      | (YoutubeVideoType & { type: 'video' })
      | (YoutubeCommentType & { type: 'comment' })
  : T extends (typeof IntegrationName)['SLACK']
  ? SlackConversation
  : T extends (typeof IntegrationName)['GMAIL']
  ? GmailEmailType
  : T extends (typeof IntegrationName)['GOOGLE_CALENDAR']
  ? GoogleCalendarEventType
  : T extends (typeof IntegrationName)['NOTION']
  ? NotionPage
  : T extends (typeof IntegrationName)['REDDIT']
  ? { comment?: RedditCommentType | null; post?: RedditPostType | null }
  : unknown
export type IntegrationContextDataType<
  T extends IntegrationName = IntegrationName,
> = T extends (typeof IntegrationName)['SLACK']
  ? SlackContextDataType
  : T extends (typeof IntegrationName)['GMAIL']
  ? GmailContextDataType
  : T extends (typeof IntegrationName)['GOOGLE_CALENDAR']
  ? GoogleCalendarContextDataType
  : T extends (typeof IntegrationName)['NOTION']
  ? NotionContextDataType
  : unknown

export type ContextWithItems = Context & {
  items: ContextItem[]
}

export const ContextLoadInputSchema = z
  .object({
    contextId: z.string(),
    integrationName: z.nativeEnum(IntegrationName),
  })
  .or(
    z.object({
      taskId: z.string(),
      integrationName: z.nativeEnum(IntegrationName),
    })
  )

export const CONTEXT_INCLUDE_ITEMS = {
  items: {
    orderBy: {
      order: 'asc',
    },
  },
} as const
