import { useIntegrationListQueryResult } from '@/web/hooks/useIntegrationListQueryResult'

export const useNeedsIntegration = () => {
  const integrationsQueryResult = useIntegrationListQueryResult()
  if (!integrationsQueryResult.isSuccess) {
    return null
  }

  return !integrationsQueryResult.data?.some(integration => {
    return integration.isEnabled
  })
}
