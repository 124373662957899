import {
  PricingTierId,
  TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT,
} from '@/shared/constants/pricing'
import { getFeatureData } from '@/shared/utils/pricing'
import { UsageProgressBar } from '@/web/components/UsageProgressBar'
import { usePricingTierId } from '@/web/hooks/useCurrentPricingTierId'
import { api } from '@/web/utils/api'
import { type ActivityType, type IntegrationName } from '@prisma/client'
import type { ComponentProps } from 'react'

export function ActivityUsageProgressBar({
  activityType,
  integrationName,
  ...usageProgressBarProps
}: Partial<ComponentProps<typeof UsageProgressBar>> & {
  activityType: ActivityType
  integrationName?: IntegrationName
}) {
  const currentTierId = usePricingTierId()

  const usageCountQueryResult = api.usage.count.useQuery()
  const usageCount = usageCountQueryResult.data

  const limit =
    TIER_ACTIVITY_OR_FEATURE_TYPE_LIMIT[currentTierId ?? PricingTierId.FREE][
      activityType
    ]
  const limitCount = limit != null && 'count' in limit ? limit.count : null

  const monthUsageCount =
    usageCount?.byMonth.find(
      activityLogCount => activityLogCount.activityType === activityType
    )?.count ?? 0

  const featureData = getFeatureData(activityType, integrationName)

  if (!featureData) {
    return null
  }

  return (
    <UsageProgressBar
      key={activityType}
      info={featureData.description}
      limit={limitCount}
      current={monthUsageCount}
      title={featureData.name}
      isLoading={usageCountQueryResult.isLoading}
      {...usageProgressBarProps}
    />
  )
}
