import { type SVGProps } from 'react'

export const LogoStacked = ({
  isDark,
  ...svgProps
}: { isDark?: boolean } & Partial<SVGProps<SVGSVGElement>>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='20 18 203.07 125'
    {...svgProps}>
    <path
      fill={isDark ? 'black' : 'currentColor'}
      d='m108.88,112.94h0c-.94-1.23-2.13-2.22-3.55-2.97-1.42-.74-3.14-1.12-5.1-1.12-1.55,0-2.99.31-4.29.93-.99.47-1.86,1.16-2.59,2.06v-2.34h-7.14v31.69h7.46v-9.02c.73.83,1.61,1.5,2.61,1.98,1.31.63,2.77.95,4.32.95,1.83,0,3.46-.36,4.84-1.07,1.37-.71,2.53-1.68,3.46-2.87.91-1.19,1.61-2.57,2.06-4.11.45-1.53.67-3.14.67-4.79,0-1.74-.23-3.44-.67-5.04-.45-1.62-1.15-3.06-2.08-4.28Zm-4.99,11.9c-.18.88-.48,1.66-.9,2.31-.42.65-.97,1.18-1.64,1.58-.66.4-1.51.6-2.52.6s-1.82-.2-2.5-.6c-.69-.4-1.25-.93-1.66-1.57-.42-.66-.73-1.44-.93-2.32-.2-.9-.29-1.83-.29-2.76s.09-1.91.27-2.82c.18-.88.48-1.67.9-2.34.42-.66.97-1.2,1.64-1.62.66-.41,1.51-.62,2.52-.62s1.81.21,2.47.62c.68.42,1.24.97,1.67,1.65.44.69.76,1.48.95,2.37.2.9.29,1.83.29,2.76s-.09,1.87-.27,2.77Z'
    />
    <path
      fill={isDark ? 'black' : 'currentColor'}
      d='m128.71,122.59c0,2.36-.38,4.06-1.13,5.06-.73.97-1.91,1.45-3.6,1.45-1.45,0-2.42-.42-2.97-1.28-.58-.91-.87-2.34-.87-4.24v-14.06h-7.46v15.28c0,1.52.14,2.93.41,4.18.28,1.28.77,2.39,1.45,3.3.7.91,1.66,1.63,2.86,2.12,1.18.49,2.71.73,4.53.73,1.45,0,2.9-.33,4.29-.97,1.1-.51,2.04-1.28,2.83-2.28v2.65h7.14v-24.99h-7.46v13.08Z'
    />
    <rect
      fill={isDark ? 'black' : 'currentColor'}
      x='139.41'
      y='102.06'
      width='7.46'
      height='32.44'
    />
    <path
      fill={isDark ? 'black' : 'currentColor'}
      d='m169.61,121.25c-.75-.54-1.61-.97-2.56-1.29-.93-.31-1.89-.59-2.87-.81-.94-.22-1.86-.42-2.77-.61-.89-.18-1.68-.39-2.36-.62-.65-.22-1.18-.5-1.57-.83-.34-.29-.51-.67-.51-1.14,0-.4.09-.7.28-.93.21-.25.46-.43.76-.56.32-.14.68-.23,1.07-.27.42-.05.82-.07,1.17-.07,1.11,0,2.08.21,2.89.63.74.39,1.14,1.12,1.23,2.26l.03.38h7.14l-.04-.45c-.13-1.56-.54-2.87-1.21-3.91-.67-1.03-1.52-1.87-2.54-2.49-1-.61-2.16-1.06-3.43-1.31-2.51-.51-5.28-.5-7.8-.02-1.29.24-2.46.67-3.5,1.26-1.05.61-1.91,1.45-2.55,2.5-.64,1.06-.97,2.42-.97,4.05,0,1.13.24,2.11.71,2.9.46.78,1.09,1.44,1.85,1.95.74.5,1.61.91,2.56,1.22.93.3,1.89.56,2.87.78,2.33.49,4.17.99,5.48,1.48,1.16.44,1.72,1.04,1.72,1.86,0,.5-.12.92-.34,1.23-.24.34-.55.61-.92.82-.39.21-.82.37-1.29.47-1.17.25-2.21.18-3.18-.07-.56-.14-1.05-.37-1.48-.66-.42-.29-.76-.67-1.02-1.13-.26-.45-.39-1-.39-1.64v-.41h-7.15l.02.43c.06,1.69.45,3.11,1.16,4.25.7,1.12,1.61,2.04,2.7,2.73,1.08.68,2.33,1.17,3.71,1.46,1.36.29,2.77.43,4.2.43s2.77-.14,4.12-.41c1.37-.27,2.6-.76,3.67-1.44,1.08-.69,1.96-1.61,2.63-2.73.67-1.13,1.02-2.55,1.02-4.21,0-1.19-.24-2.21-.71-3.03-.47-.81-1.09-1.5-1.85-2.05Z'
    />
    {/* e */}
    <path
      fill={isDark ? 'black' : 'currentColor'}
      d='m197.3,118.14c-.45-1.76-1.19-3.35-2.19-4.72-1.02-1.39-2.33-2.5-3.91-3.32-1.58-.82-3.45-1.24-5.57-1.24-1.89,0-3.63.34-5.19,1.01-1.55.67-2.91,1.61-4.04,2.78-1.12,1.17-2,2.58-2.61,4.18-.61,1.6-.92,3.35-.92,5.2s.3,3.69.89,5.29c.59,1.6,1.45,3,2.54,4.16,1.1,1.16,2.45,2.07,4.03,2.69,1.57.63,3.35.94,5.29.94,2.81,0,5.24-.65,7.24-1.94,2-1.29,3.51-3.46,4.47-6.44l.17-.54h-6.72l-.09.3c-.19.65-.73,1.29-1.62,1.9-.89.62-1.98.93-3.22.93-1.75,0-3.1-.45-4.01-1.33-.85-.82-1.35-2.15-1.49-3.97h17.39l.03-.39c.13-1.91-.04-3.76-.48-5.51Zm-16.91.86c.05-.32.13-.68.24-1.06.15-.53.43-1.05.81-1.54.38-.48.9-.89,1.55-1.22.64-.33,1.47-.49,2.45-.49,1.49,0,2.61.39,3.32,1.17.66.73,1.15,1.78,1.46,3.14h-9.83Z'
    />
    {/* Outer blob */}
    <path
      mask='url(#middleBlob)'
      fill={isDark ? 'black' : 'currentColor'}
      opacity={0.5}
      d='m74.7,31.28s0,0,0,0c-.69,0-1.38.02-2.07.06-4.98-.1-9.52-2.02-12.97-5.13-.33-.29-.64-.6-.95-.92-4.12-3.74-9.6-6.03-15.61-6.03-12.83,0-23.23,10.4-23.23,23.23,0,6.24,2.47,11.9,6.47,16.08.8.83,1.65,1.6,2.57,2.31,3.36,3.58,5.42,8.39,5.42,13.69s-2.01,10-5.31,13.57c-.4.44-.82.85-1.26,1.25-4.61,4.89-7.45,11.48-7.45,18.73,0,15.08,12.22,27.3,27.3,27.3,12.25,0,22.62-8.07,26.07-19.19.19-.62.35-1.24.5-1.88,1.81-5.65,6.07-10.2,11.52-12.42.62-.2,1.23-.41,1.84-.64,13.64-5.18,23.34-18.37,23.34-33.83,0-19.98-16.2-36.18-36.18-36.18Z'
    />
    {/* Middle blob */}
    <path
      fill={isDark ? 'black' : 'currentColor'}
      opacity={0.5}
      d='m52.38,119.83c8.96,0,16.55-5.9,19.07-14.04.14-.45.26-.91.37-1.37,1.33-4.13,4.44-7.46,8.43-9.09.45-.15.9-.3,1.34-.47,9.98-3.79,17.07-13.44,17.07-24.75,0-14.62-11.85-26.47-26.47-26.47h0c-.51,0-1.01.02-1.51.05-3.65-.08-6.96-1.48-9.49-3.75-.24-.22-.47-.44-.69-.67-3.02-2.74-7.02-4.41-11.42-4.41-9.39,0-16.99,7.61-16.99,16.99,0,4.57,1.8,8.71,4.73,11.76.58.61,1.21,1.17,1.88,1.69,2.46,2.62,3.97,6.14,3.97,10.01s-1.47,7.32-3.88,9.93c-.29.32-.6.63-.92.92-3.38,3.58-5.45,8.39-5.45,13.7,0,11.03,8.94,19.97,19.97,19.97Z'
    />
    {/* Center blob */}
    <path
      fill={isDark ? 'black' : 'currentColor'}
      d='m69.69,56.01s0,0,0,0c-.32,0-.64.01-.96.03-2.31-.05-4.41-.94-6-2.37-.15-.14-.3-.28-.44-.42-1.91-1.73-4.45-2.79-7.23-2.79-5.94,0-10.76,4.82-10.76,10.76,0,2.89,1.14,5.51,3,7.44.37.38.77.74,1.19,1.07,1.56,1.66,2.51,3.89,2.51,6.34s-.93,4.63-2.46,6.28c-.19.2-.38.4-.58.58-2.14,2.26-3.45,5.31-3.45,8.67,0,6.98,5.66,12.64,12.64,12.64,5.67,0,10.47-3.74,12.07-8.88.09-.28.16-.58.23-.87.84-2.62,2.81-4.72,5.34-5.75.29-.09.57-.19.85-.3,6.32-2.4,10.8-8.51,10.8-15.66,0-9.25-7.5-16.75-16.75-16.75Z'
    />
  </svg>
)
